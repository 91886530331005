import { CustomTable } from '../../../components/table';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import Box from '@mui/material/Box';
import { useLoading } from '../../../context/loading.context';
import {
  organizationList,
  addOrganization,
  getOrganization,
  updateOrganization,
  getSystemEmailDefinition,
} from '../../../services/OrganizationAPIService';
import { useNavigate, useLocation } from 'react-router-dom';
import { Column } from '../../../components/table/table';
import RenderOrganization from './renderOrganization';
import AppBarMenu from '../../../components/AppBar';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const _ = require('lodash');

let ExpirePasswordDefault = true;
let PasswordLifeDefault = '60';
let PasswordExpiryNotificationDefault = true;
let PasswordChangeReminderDaysDefault = '5';
let RPMServiceDefault = false;
let HasParentDefault = false;
let NutritionServiceDefault = false;

interface BtnsAndEvent {
  text: string;
  click: any;
  variant: string;
}

const OrganizationList = (props: any) => {
  let location = useLocation();

  const [organizations, setOrganizations] = useState<any[]>([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState<any[]>([]);
  const [filteredOrganizationsPage, setFilteredOrganizationsPage] = useState<any[]>([]);

  const [organizationName, setOrganizationName] = React.useState('');
  const [organizationNameError, setOrganizationNameError] = React.useState(false);

  const [expirePassword, setExpirePassword] = React.useState(ExpirePasswordDefault);
  const [passwordLife, setPasswordLife] = React.useState(PasswordLifeDefault);

  const [passwordExpiryNotification, setPasswordExpiryNotification] = React.useState(PasswordExpiryNotificationDefault);
  const [passwordChangeReminderDays, setPasswordChangeReminderDays] = React.useState(PasswordChangeReminderDaysDefault);

  const [hasParent, setHasParent] = React.useState(HasParentDefault);
  const [parentOrganization, setParentOrganization] = React.useState(-1);

  const [rpmService, setRpmService] = React.useState(RPMServiceDefault);
  const [nutritionService, setNutritionService] = React.useState(NutritionServiceDefault);

  const [ORGS, setORGS] = React.useState();

  const [currentView, setCurrentView] = React.useState('list');
  const [currentOrgId, setCurrentOrgId] = React.useState(-1);

  const { loading, setLoading } = useLoading();
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(false);

  const [searchByOrgName, setSearchByOrgName] = React.useState('');
  const [searchByOrgID, setSearchByOrgID] = React.useState('');

  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = React.useState(0);

  const [systemEmailDefinition, setSystemEmailDefinition] = useState<any[]>([]);
  const [systemEmailData, setSystemEmailData] = useState<any[]>([]);
  const [init, setInit] = useState<any[]>([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (location.state && location.state.name === 'list') {
      setCurrentView('list');
    }
    if (searchByOrgName.length == 0 && searchByOrgID.length == 0) fetchOrganizations();
  }, [location, update]);

  const onClickEditOrganization = async (org: any) => {
    let success = await populateData(org);
    if (success) setCurrentView('edit');
  };

  const onClickEditShownOrganization = async (org: any) => {
    setCurrentView('edit');
  };

  const onClickViewOrganization = async (org: any) => {
    let success = await populateData(org);
    if (success) setCurrentView('view');
  };

  const onPageChanged = async (value: any, orgs: any = null) => {
    const res = paginate(orgs ? orgs : filteredOrganizations, 10, value);
    setFilteredOrganizationsPage(res);

    let total = orgs ? orgs.length : filteredOrganizations.length;
    setPagesCount(Math.ceil(total / 10));
    setPage(value);
  };

  function paginate(array: any, page_size: any, page_number: any) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const populateData = async (org: any) => {
    let data = await getOrganization({ ORGID: org.ORGID });
    //assign data to form
    if (data.IsSuccess) {
      setCurrentOrgId(data.Data.org[0].ORGID);
      let org = data.Data.org[0];
      let policy = data.Data.policy;
      setSystemEmailData(data.Data.emailSystem);

      setOrganizationName(org.OrganizationName);
      if (!org.IsParentOrg) {
        setHasParent(true);
        setParentOrganization(org.ParentOrgId);
      }
      let expirePassword = policy.find((o: any) => o.PolicyCode === 'ExpirePassword');
      setExpirePassword(expirePassword ? stringToBool(expirePassword) : ExpirePasswordDefault);

      let passwordLife = policy.find((o: any) => o.PolicyCode === 'PasswordLife');
      setPasswordLife(passwordLife ? passwordLife.ConfigValue : PasswordLifeDefault);

      let passwordExpiryNotification = policy.find((o: any) => o.PolicyCode === 'PasswordExpiryNotification');
      setPasswordExpiryNotification(
        passwordExpiryNotification ? stringToBool(passwordExpiryNotification) : PasswordExpiryNotificationDefault
      );

      let passwordChangeReminderDays = policy.find((o: any) => o.PolicyCode === 'PasswordChangeReminderDays');
      setPasswordChangeReminderDays(
        passwordChangeReminderDays ? passwordChangeReminderDays.ConfigValue : PasswordChangeReminderDaysDefault
      );

      let rPMService = policy.find((o: any) => o.PolicyCode === 'RPMService');
      setRpmService(rPMService ? stringToBool(rPMService) : RPMServiceDefault);

      let nutritionService = policy.find((o: any) => o.PolicyCode === 'NutritionService');
      setNutritionService(nutritionService ? stringToBool(nutritionService) : NutritionServiceDefault);

      return true;
    }
    return false;
  };

  const [columns, setColumns] = useState<Column[]>([
    {
      label: '  ',
      accessor: 'Image',
      sortable: false,
      type: 'image',
      imageFallBack: 'Username',
    },
    { label: 'OrganizationName', accessor: 'OrganizationName', sortable: true },
    { label: 'ORGID', accessor: 'ORGID', sortable: true },
    { label: 'Customers', accessor: 'CustomerCount', sortable: true },
    {
      label: 'View',
      accessor: '',
      sortable: false,
      action: 'view',
      actionEvent: onClickViewOrganization,
    },
    {
      label: 'Edit',
      accessor: '',
      sortable: false,
      action: 'edit',
      actionEvent: onClickEditOrganization,
    },
  ]);

  const fetchOrganizations = async () => {
    setLoading(true);
    try {
      const email = await getSystemEmailDefinition();
      if (email.IsSuccess) {
        if (systemEmailDefinition.length == 0) {
          setSystemEmailDefinition(email.Data.Data);
          setInit(_.cloneDeep(email.Data.Data));
        }
      }

      const response = await organizationList();
      if (response.IsSuccess) {
        //so the last orgs are first 
        response.Data.Data.sort((a: any, b: any) => a.ORGID > b.ORGID ? -1 : 1)

        setOrganizations(response.Data.Data);
        setFilteredOrganizations(response.Data.Data);
        setORGS(response.Data.Data);
        onPageChanged(1, response.Data.Data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSearchInput = (input: any, field: string, setter: any, cleanSet: any) => {
    const res = organizations.filter((org: any) => org[field] != null && org[field].toLowerCase().includes(input.toLowerCase()));
    setter(input);
    cleanSet('');
    if (input.length > 0) {
      setFilteredOrganizations(res);
      onPageChanged(1, res);
    } else {
      setFilteredOrganizations(organizations);
      onPageChanged(1, organizations);
    }
  };

  const handleSearchInputNumber = (input: any, field: string, setter: any, cleanSet: any) => {
    const res = organizations.filter((org: any) => org[field].toString().includes(input));
    cleanSet('');
    setter(input);
    if (input.length > 0) {
      setFilteredOrganizations(res);
      onPageChanged(1, res);
    } else {
      setFilteredOrganizations(organizations);
      onPageChanged(1, organizations);
    }
  };

  const onClickCreateOrganization = async () => {
    let resp = await addOrganization(orgData());

    if (resp.IsSuccess) toast('Organization was saved!', { type: 'success' });
    else toast('Sorry, something went wrong!', { type: 'error' });

    if (resp.IsSuccess) {
      fetchOrganizations();
      setCurrentView('list');
    } else {
      if (resp.Data && resp.Data.Data && resp.Data.Data.length > 0 && resp.Data.Data[0].message)
        if (resp.Data.Data[0].message === 'Organization Name already exists.') setOrganizationNameError(true);
    }
  };

  const onClickUpdateOrganization = async () => {
    if (currentOrgId) {
      let res = await updateOrganization(orgData());

      if (res.IsSuccess) toast('Organization was updated!', { type: 'success' });
      else toast('Sorry, something went wrong!', { type: 'error' });

      fetchOrganizations();
      setCurrentView('list');

    } else {
      console.log('currentOrgId not set, fix');
    }
  };

  const onClickOrganizationList = () => {
    clearForm();
    setCurrentView('list');
  };

  const onClickAddOrganization = () => {
    clearForm();
    setCurrentView('add');
  };

  function stringToBool(param: any) {
    return param.ConfigValue === '0' ? false : true;
  }

  const clearForm = () => {
    setOrganizationName('');
    setHasParent(HasParentDefault);
    setParentOrganization(-1);

    setExpirePassword(ExpirePasswordDefault);
    setPasswordLife(PasswordLifeDefault);
    setPasswordExpiryNotification(PasswordExpiryNotificationDefault);
    setPasswordChangeReminderDays(PasswordChangeReminderDaysDefault);
    setRpmService(RPMServiceDefault);
    setNutritionService(NutritionServiceDefault);

    setOrganizationNameError(false);
    // setSystemEmailDefinition(init);
  };

  const orgData = () => {
    let array = prepSystemEmailData();
    return {
      organizationName,
      expirePassword,
      passwordLife: Number(passwordLife),
      passwordExpiryNotification,
      passwordChangeReminderDays: Number(passwordChangeReminderDays),
      hasParent,
      parentOrganization,
      rpmService,
      nutritionService,
      ORGID: currentOrgId,
      systemEmailData: array,
    };
  };

  const prepSystemEmailData = () => {
    let array = _.cloneDeep(systemEmailData);
    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      if (item.UseDefaultTemplate) {
        array[i].CustomTemplate = '';
        array[i].CustomOrgLogo = '';
      }
    }
    return array;
  };

  const buttons = () => {

    let btn1: BtnsAndEvent = { text: 'Organization List', click: onClickOrganizationList, variant: "outlined" };
    let btn2: BtnsAndEvent = { text: 'Add Organization', click: onClickAddOrganization, variant: "outlined" };

    let btn: BtnsAndEvent = { text: '', click: null, variant: "outlined" };
    if (currentView == 'add')
      btn = { text: 'Create Organization', click: onClickCreateOrganization, variant: "contained" };

    if (currentView == 'view')
      btn = { text: 'Edit Organization', click: onClickEditShownOrganization, variant: "contained" };

    if (currentView == 'edit')
      btn = { text: 'Update Organization', click: onClickUpdateOrganization, variant: "contained" };

    let arr = [btn1, btn2]

    if (btn.text.length > 0)
      arr.push(btn);

    return arr;
  };

  return (
    <IntlProvider messages={{}} locale="de" defaultLocale="en">
      <AppBarMenu
        title="Organization Tool"
        buttons={buttons()}
        open={open}
        width={props.props.width}
      />

      <Box
        component="div"
        sx={{
          display: 'flex',
          p: 1,
          flexDirection: 'column',
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 5,
        }}
      >
        {currentView == 'add' && (
          <div>
            <RenderOrganization
              headerText={'Create Organization Form'}
              buttonText={'Create Organization'}
              currentView={currentView}
              parent={OrganizationList}
              organizationName={organizationName}
              setOrganizationName={setOrganizationName}
              expirePassword={expirePassword}
              setExpirePassword={setExpirePassword}
              passwordLife={passwordLife}
              setPasswordLife={setPasswordLife}
              passwordExpiryNotification={passwordExpiryNotification}
              setPasswordExpiryNotification={setPasswordExpiryNotification}
              passwordChangeReminderDays={passwordChangeReminderDays}
              setPasswordChangeReminderDays={setPasswordChangeReminderDays}
              hasParent={hasParent}
              setHasParent={setHasParent}
              parentOrganization={parentOrganization}
              setParentOrganization={setParentOrganization}
              rpmService={rpmService}
              setRpmService={setRpmService}
              nutritionService={nutritionService}
              setNutritionService={setNutritionService}
              ORGS={ORGS}
              onClick={onClickCreateOrganization}
              organizationNameError={organizationNameError}
              systemEmail={systemEmailDefinition}
              systemEmailData={systemEmailData}
              setSystemEmailData={setSystemEmailData}
            />
          </div>
        )}

        {currentView == 'list' && (
          <div>
            <div style={{ display: 'flex' }}>
              <TextField
                style={{ width: 300 }}
                value={searchByOrgName}
                fullWidth
                label="Search by Organization Name"
                variant="outlined"
                onChange={(event) => {
                  handleSearchInput(event.target.value, 'OrganizationName', setSearchByOrgName, setSearchByOrgID);
                }}
              />
              <TextField
                style={{ marginLeft: 10, width: 200 }}
                value={searchByOrgID}
                fullWidth
                label="Search by ORG ID"
                variant="outlined"
                onChange={(event) => {
                  handleSearchInputNumber(event.target.value, 'ORGID', setSearchByOrgID, setSearchByOrgName);
                }}
              />
            </div>
            <CustomTable
              data={filteredOrganizationsPage}
              columns={columns}
              onRowClicked={onClickViewOrganization}
              onPageChanged={onPageChanged}
              pagesCount={pagesCount}
              page={page}
            />
          </div>
        )}
        {currentView == 'view' && (
          <div>
            <RenderOrganization
              headerText={'View Organization Form (Read Only)'}
              buttonText={'Edit Organization'}
              currentView={currentView}
              parent={OrganizationList}
              organizationName={organizationName}
              setOrganizationName={setOrganizationName}
              expirePassword={expirePassword}
              setExpirePassword={setExpirePassword}
              passwordLife={passwordLife}
              setPasswordLife={setPasswordLife}
              passwordExpiryNotification={passwordExpiryNotification}
              setPasswordExpiryNotification={setPasswordExpiryNotification}
              passwordChangeReminderDays={passwordChangeReminderDays}
              setPasswordChangeReminderDays={setPasswordChangeReminderDays}
              hasParent={hasParent}
              setHasParent={setHasParent}
              parentOrganization={parentOrganization}
              setParentOrganization={setParentOrganization}
              rpmService={rpmService}
              setRpmService={setRpmService}
              nutritionService={nutritionService}
              setNutritionService={setNutritionService}
              ORGS={ORGS}
              onClick={onClickEditShownOrganization}
              systemEmail={systemEmailDefinition}
              systemEmailData={systemEmailData}
              setSystemEmailData={setSystemEmailData}
            />
          </div>
        )}
        {currentView == 'edit' && (
          <div>
            <RenderOrganization
              headerText={'Edit Organization Form'}
              buttonText={'Update Organization'}
              currentView={currentView}
              parent={OrganizationList}
              organizationName={organizationName}
              setOrganizationName={setOrganizationName}
              expirePassword={expirePassword}
              setExpirePassword={setExpirePassword}
              passwordLife={passwordLife}
              setPasswordLife={setPasswordLife}
              passwordExpiryNotification={passwordExpiryNotification}
              setPasswordExpiryNotification={setPasswordExpiryNotification}
              passwordChangeReminderDays={passwordChangeReminderDays}
              setPasswordChangeReminderDays={setPasswordChangeReminderDays}
              hasParent={hasParent}
              setHasParent={setHasParent}
              parentOrganization={parentOrganization}
              setParentOrganization={setParentOrganization}
              rpmService={rpmService}
              setRpmService={setRpmService}
              nutritionService={nutritionService}
              setNutritionService={setNutritionService}
              ORGS={ORGS}
              onClick={onClickUpdateOrganization}
              systemEmail={systemEmailDefinition}
              systemEmailData={systemEmailData}
              setSystemEmailData={setSystemEmailData}
            />
          </div>
        )}
      </Box>
      <ToastContainer position="bottom-center" />
    </IntlProvider>
  );
};

export default React.memo(OrganizationList);
