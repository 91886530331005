import { AlertMessages } from '../../../data/popup-messages';
import { dispatch, actions } from '../../../store';
import { login } from '../../../services/AuthAPIService';
import { LoginData } from '../../../store/slices/login/type';
import { useLoading } from '../../../context/loading.context';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useEffect } from 'react';

export function LoginForm() {
  const { loading, setLoading } = useLoading();
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const [formError, setFormError] = React.useState({
    username: false,
    password: false,
    secretKey: false,
  });
  const [logInError, setLogInError] = React.useState('');

  const showLoginError = (data: any) => {
    if (!data.Error_Message) {
      setLogInError(AlertMessages.login.signInFailed.message);
      return;
    }
    switch (data.Error_Message) {
      case 'Account Deleted':
        setLogInError(AlertMessages.login.accountDeleted.message);
        break;
      case 'Account Inactive':
        setLogInError(AlertMessages.login.accountDeactivated.message);
        break;
      case 'Account disabled':
        setLogInError(AlertMessages.login.accountLocked.message);
        break;
      case 'Unknown Error':
        setLogInError(AlertMessages.login.signInFailed.message);
        break;
      case 'Failed to create session':
        setLogInError(AlertMessages.login.signInFailed.message);
        break;
      case 'Invalid user name':
        setLogInError('Username or password is incorrect.');
        return;
      case 'Invalid user password':
        setLogInError('Username or password is incorrect.');
        return;
      default:
        setLogInError(AlertMessages.login.signInFailed.message);
    }
  };
  const state = useSelector((s: any) => {
    if (s.loginReducer.isLoggedIn) {
      navigate('/accountTransfer');
    }
    return (s as any).loginReducer;
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const { t } = useTranslation();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get('username') === '' || data.get('password') === '') return;
    let res = {
      username: data.get('username'),
      password: data.get('password'),
    };
    setLoading(true);
    const response = await login(res.username, res.password);
    if (response.IsSuccess) dispatch(actions.loginActions.loginSuccess(response.Data as LoginData));
    else {
      showLoginError(response.Data);
    }
    setLoading(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, width: '350px' }}>

      {/* username */}

      <Typography
        color="custom_grey.main"
        align="left"
        sx={{
          marginTop: 1,
          fontSize: '18px',
          fontFamily: 'ProximaNovaSoftSemibold',
        }}
      >
        {'Username'}
      </Typography>
      <OutlinedInput
        fullWidth
        size="small"
        id="username"
        name="username"
        required
        error={formError.username ? true : false}
        label=""
        sx={{ marginTop: 1, borderRadius: '8px' }}
        onChange={(event) => {
          setLogInError('');
          if (event.target.value === '') {
            setFormError({
              username: true,
              password: formError.password,
              secretKey: false,
            });
          } else {
            setFormError({
              username: false,
              password: formError.password,
              secretKey: false,
            });
          }
        }}
      />
      {formError.username && (
        <Typography
          color="error.main"
          align="left"
          sx={{
            fontSize: '18px',
            fontFamily: 'ProximaNovaMedium',
          }}
        >
          {'Please enter username'}
        </Typography>
      )}

      {/* password */}

      <Typography
        color="custom_grey.main"
        align="left"
        sx={{
          marginTop: 1,
          fontSize: '18px',
          fontFamily: 'ProximaNovaSoftSemibold',
        }}
      >
        {'Password'}
      </Typography>
      <OutlinedInput
        fullWidth
        size="small"
        id="password"
        name="password"
        required
        error={formError.password ? true : false}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              color={formError.password ? 'error' : 'primary'}
            >
              {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
            </IconButton>
          </InputAdornment>
        }
        label=""
        sx={{ marginTop: 1, borderRadius: '8px' }}
        onChange={(event) => {
          setLogInError('');
          if (event.target.value === '') {
            setFormError({
              username: formError.username,
              password: true,
              secretKey: false,
            });
          } else {
            setFormError({
              username: formError.username,
              password: false,
              secretKey: false,
            });
          }
        }}
      />
      {formError.password && (
        <Typography
          color="error.main"
          align="left"
          sx={{
            mt: 1,
            fontSize: '18px',
            fontFamily: 'ProximaNovaMedium',
          }}
        >
          {'Please enter password'}
        </Typography>
      )}
      {logInError !== '' && (
        <Typography
          color="error.main"
          align="left"
          sx={{
            mt: 1,
            fontSize: '18px',
            fontFamily: 'ProximaNovaMedium',
          }}
        >
          {logInError}
        </Typography>
      )}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        sx={{
          mt: 3,
          mb: 2,
          height: '40px',
          fontSize: '20px',
          fontFamily: 'ProximaNovaSoftSemibold',
          borderRadius: '8px',
        }}
      >
        {t('Sign In')}
      </Button>
    </Box>
  );
}
