import axios from 'axios';

import { BACKEND_ENDPOINT } from '../config/Constant';
import { requestTemplate } from './RequestTemplate';
import { getState, dispatch, actions } from '../store';
import { LoginData, LoginState } from '../store/slices/login/type';

export async function getFormDetails(formGUID: string) {
  console.log('getFormDetails', formGUID);

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'mozzazportalgetformdetails',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      FormGUID: formGUID,
      CustomerId: session.sessionData.CustomerId,
      SessionToken: session.sessionData.AuthToken,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT}`, req);

  console.log('req', req, 'response', data);

  if (!data || data.err) {
    console.log(data.err);
    return;
  }
  return data;
}

export async function getFormHistory(formId: string) {

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'mozzazportalgetformhistory',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      FormId: formId,
      CustomerId: session.sessionData.CustomerId,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT}`, req);

  console.log('req', req, 'response', data);

  if (!data || data.err) {
    console.log(data.err);
    return;
  }

  return data;
}

export async function editForm(formHistory: any, formId: any) {
  console.log('editForm', formHistory);

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'mozzazportaleditform',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      FormId: formId,
      ObservationOccuranceId: formHistory.FormHistory[0].ObservationOccuranceId,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT}`, req);

  console.log('req', req, 'response', data);

  if (!data || data.err) {
    console.log(data.err);
    return;
  }

  return data;
}

export async function viewFormSubmission(formHistory: any, formId: any) {
  console.log('viewFormSubmission', formHistory);

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'mozzazportalviewformsubmission',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      FormId: formId,
      ObservationOccuranceId: formHistory.FormHistory[0].ObservationOccuranceId,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT}`, req);

  console.log('req', req, 'response', data);

  if (!data || data.err) {
    console.log(data.err);
    return;
  }

  return data;
}

export async function getFormList() {
  console.log('getFormList');

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'mozzazportalgetformlist',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: session.sessionData.CustomerId,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT}`, req);

  console.log('req', req, 'response', data);

  if (!data || data.err) {
    console.log(data.err);
    return;
  }

  return data;
}
