import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoginData, LoginState } from './type'

const initialState: LoginState = { isLoggedIn: false, sessionData: null };

const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<LoginData>) {
      return { isLoggedIn: true, sessionData: action.payload };
    },
    logout(state, action: PayloadAction<null>) {
      return { isLoggedIn: false, sessionData: null };
    }
  }
})

export const { actions, reducer } = loginSlice;