import DashboardIcon from '@mui/icons-material/Dashboard';
import AppBarMenu from '../components/AppBar';
import { IntlProvider } from 'react-intl';
import Box from '@mui/material/Box';

export const Dashboard = (props: any) => {
  return (
    <IntlProvider messages={{}} locale="de" defaultLocale="en">
      <AppBarMenu title="Dashboard" open={true} width={props.width} />
      <Box
        component="div"
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 1,
          flexDirection: 'column',
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 5,
        }}
      >
        <div>
          <DashboardIcon />
          <h3>What can you do with the Mozzaz Care Tools?</h3>
          <h4>Organization tab:</h4>
          <p>Create, Read and Edit an Organization</p>
          <p>- Name</p>
          <p>- Police Configuration</p>
          <p>- System Email Definition</p>
          <h4>Customers tab:</h4>
          <p>Search by Username, edit role and edit organization user belongs to</p>
          <p>- Individual changed to a new Org will lose all careteams from the previous Org</p>
          <p>- Professional changed to a new Org will no longer be part of any careteam on the previous Org</p>
          <h3>News</h3>
          <p>1st Ver. live, Jan 2024</p>
        </div>
      </Box>
    </IntlProvider>
  );
};
