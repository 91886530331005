import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";
import { Controller } from "react-hook-form";
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";
import { createFalse } from "typescript";
import { FormAnswers } from "../../data/model/formQuestion";
import { FormInputProps } from "./FormInputProps";

export const FormInputRadio: React.FC<FormInputProps> = ({
  name,
  control,
  question,
  isEdit
}) => {
  const generateRadioOptions = () => {
    return question?.Answers.map((answer : FormAnswers) => (
      <FormControlLabel
        key={answer.AnswerId}
        value={answer.AnswerId}
        label={answer.AnswerText}
        control={<Radio
          sx={{
          color: "#0074BF",
          '&.Mui-checked': {
            color: "#0074BF",
          },
        }} />}
      />
    ));
  };

  return (
    <FormControl component="fieldset">
      <Controller
        name={name}
        control={control}
        defaultValue={isEdit ? (question?.answerId === 0 ? "" : question?.answerId) : ""}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <RadioGroup value={value} onChange={onChange} >
            {generateRadioOptions()}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};