import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export function ResetPasswordInfo() {
    return (
        <Box>
            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ marginTop: 2, fontSize: "22px", fontFamily: "proximaNovaSoftSemibold", color: " custom_dark_grey.main" }}
            >
                Reset Password?
            </Typography>

            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ marginTop: 2, fontSize: "18px", fontFamily: "ProximaNovaSoftMedium", color: " custom_dark_grey.main" }}
            >
                Enter a new password to reset the password for your account.
            </Typography>
        </Box>
    )
}