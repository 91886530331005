import * as React from "react";
import Box from "@mui/material/Box";
import MozzazPortalIcon from "../assets/img/mozzaz_portal_icon.png";

export function AppLogo() {
    return (
        <Box style={{
            height: "68px",
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
        }}>
            <img
                src={MozzazPortalIcon}
                style={{
                    width: "91%",
                }}
                alt="Mozzaz Care Tools"
            ></img>
        </Box>
    );
}
