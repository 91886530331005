import { useEffect, useState } from 'react';
import CustomTableBody from './body';
import CustomTableHead from './head';
import Table from '@mui/material/Table';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export interface TableProps {
  data: any[];
  columns: Column[];
  onRowClicked: Function;
  onPageChanged: Function;
  pagesCount: number;
  page: number;
}

export interface Column {
  label: string;
  accessor: string;
  sortable: boolean;
  action?: string;
  actionEvent?: Function;
  type?: string;
  imageFallBack?: string;
}

const CustomTable = ({ data, columns, onRowClicked, onPageChanged, pagesCount, page }: TableProps) => {
  const [tableData, setTableData] = useState(data);

  const handlePageChange = (e: any, value: any) => {
    onPageChanged(value);
  };

  const handleSorting = (sortField: string | number, sortOrder: string) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true,
          }) * (sortOrder === 'asc' ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };

  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <>
      <Table className="table">
        <CustomTableHead columns={columns} handleSorting={handleSorting} />
        <CustomTableBody columns={columns} tableData={tableData} onRowClicked={onRowClicked} />
      </Table>
      <Stack spacing={2} style={{ paddingTop: 5, width: '100%', alignItems: 'center' }}>
        <Pagination page={page} count={pagesCount} onChange={handlePageChange} />
      </Stack>
    </>
  );
};

export default CustomTable;
