import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { login } from '../../../services/AuthAPIService';
import { useLoading } from '../../../context/loading.context';
import './ForgotPassword.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { forgotPassword } from '../../../services/AuthAPIService';
import OutlinedInput from '@mui/material/OutlinedInput';

export function ForgotPasswordForm() {
    const { loading, setLoading } = useLoading();
    const { t } = useTranslation();

    const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
    const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");

    const [user, setUser] = useState("");
    const [email, setEmail] = useState("");

    const validateEmail = (email: any) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        } else {
            return false;
        }
    };
    const handleSubmit = async () => {
        setShowSuccessMsg(false);
        setShowErrorMsg(false);
        if (email.length !== 0 && user.length !== 0 && validateEmail(email)) {
            setLoading(true);
            const response = await forgotPassword(user, email);
            setLoading(false);
            if (response.IsSuccess) {
                setShowSuccessMsg(true);
            } else {
                setErrorMsg("Username or email entered is incorrect.");
                setShowErrorMsg(true);
            }
        } else {
            setErrorMsg("Please enter valid username and email address.");
            setShowErrorMsg(true);
        }
    };

    return (
        <Box
            sx={{ mt: 4, width: '350px' }}
        >
            <Typography
                variant="body2"
                color="custom_grey.main"
                align="left"
                sx={{
                    fontSize: '18px',
                    fontFamily: "proximaNovaSoftSemibold",
                    color: `${(showErrorMsg) ? "error.main" : "custom_grey.main"}`
                }}
            >
                {'Username'}
            </Typography>
            <OutlinedInput
                fullWidth
                size="small"
                sx={{ marginTop: 1, borderRadius: "8px" }}
                error={showErrorMsg}
                required
                id="username"
                label=""
                name="username"
                autoComplete="username"
                autoFocus
                inputProps={{ style: { fontSize: '18px' } }}
                onChange={(e: any) => setUser(e.target.value)}
            />
            <Typography
                variant="body2"
                color="custom_grey.main"
                align="left"
                sx={{
                    marginTop: 0,
                    fontSize: '18px',
                    fontFamily: "proximaNovaSoftSemibold",
                    color: `${(showErrorMsg) ? "error.main" : "custom_grey.main"}`
                }}
            >
                {'Email Address'}
            </Typography>
            <OutlinedInput
                fullWidth
                size="small"
                sx={{ marginTop: 1, borderRadius: "8px" }}
                error={showErrorMsg}
                required
                id="emailaddress"
                label=""
                name="emailaddress"
                autoComplete="emailaddress"
                autoFocus
                inputProps={{ style: { fontSize: '18px' } }}
                onChange={(e) => setEmail(e.target.value)}
            />
            <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                    mt: 3,
                    mb: 2,
                    height: '40px',
                    fontSize: '20px',
                    fontFamily: "proximaNovaSoftSemibold",
                    borderRadius: '8px'
                }}
            >
                {t('Send Reset Link')}
            </Button>
            {showSuccessMsg && (<Box sx={{ marginTop: 1, fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "custom_dark_grey.main", display: "flex" }}>
                <div style={{ height: "24px", alignSelf: "center", paddingRight: "10px", color: "regular.main" }}><CheckCircleOutlineIcon sx={{ fill: "#008507" }} /></div>
                <div>Password reset email has been sent, please check your inbox.</div>
            </Box>)}
            {showErrorMsg && (<Box sx={{ marginTop: 1, fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "error.main", display: "flex" }}>
                <div style={{ paddingRight: "10px" }}><ErrorOutlineIcon style={{ fill: "error.main" }} /></div>
                <div>{errorMsg}</div>
            </Box>)}
        </Box>
    );
}
