import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import { IntlProvider } from 'react-intl';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import '../forms.css';
import Button from '@mui/material/Button';
import { convertToLocal } from '../../../utils/datetime';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';

export const FormHistory = (props: any) => {
  const navigate = useNavigate();
  return (
    <IntlProvider messages={{}} locale="de" defaultLocale="en">
      <Box
        component="div"
        sx={{
          display: 'flex',
          p: 1,
          flexDirection: 'column',
          paddingLeft: 5,
          paddingRight: 5,
          height: '100%',
        }}
      >
        <Box component="div" sx={{ display: 'flex', width: '100%' }}>
          <Box
            fontSize={20}
            component="div"
            sx={{
              display: 'flex',
              width: '100%',
              color: 'custom_dark_grey.main',
            }}
          >
            <IconButton
              type="button"
              aria-label="Back"
              sx={{paddingLeft: '0px'}}
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIosIcon color="primary" />
            </IconButton>
            <p style={{ fontFamily: 'ProximaNovaSemibold', fontSize: '20px' }}>
              Form Nameerrrrrr
            </p>
          </Box>
        </Box>
          <TableContainer sx={{ overflowX: 'initial',maxHeight: 400 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ width: '40%' }}
                    sx={{
                      color: 'custom_grey.main',
                      fontFamily: 'ProximaNovaSemibold',
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{ width: '40%' }}
                    sx={{
                      color: 'custom_grey.main',
                      fontFamily: 'ProximaNovaSemibold',
                    }}
                  >
                    Reporter
                  </TableCell>
                  <TableCell
                    style={{ width: '20%' }}
                    sx={{
                      color: 'custom_grey.main',
                      fontFamily: 'ProximaNovaSemibold',
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              {sampleHistory.map((history) => (
                <TableRow>
                  <TableCell
                    style={{ width: '40%' }}
                    sx={{
                      color: 'custom_dark_grey.main',
                      fontFamily: 'ProximaNova',
                    }}
                  >
                    {convertToLocal(history.observationLaunchDateTime)}
                  </TableCell>
                  <TableCell
                    style={{ width: '40%' }}
                    sx={{
                      color: 'custom_dark_grey.main',
                      fontFamily: 'ProximaNova',
                    }}
                  >
                    {history.RecordedBy}
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: '18px !important' }}
                    style={{ width: '20%' }}
                  >
                    <Button
                      variant="text"
                      sx={{
                        p: 0,
                        paddingRight: 2,
                        fontSize: 18,
                        alignContent:"center",
                        fontFamily: 'ProximaNova',
                      }}
                      startIcon={<VisibilityIcon color="primary" />}
                      onClick={() => {
                        navigate("/forms/history/view")
                      }}
                    >
                      View
                    </Button>
                    <Button
                      sx={{
                        p: 0,
                        fontSize: 18,
                        alignContent:"center",
                        fontFamily: 'ProximaNovaSoftSemibold',
                      }}
                      variant="text"
                      startIcon={<EditIcon color="primary" sx={{
                        fontSize: 18,
                      }} />}
                      onClick={() => {
                        navigate("/forms/history/edit")
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
      </Box>
    </IntlProvider>
  );
};

const sampleHistory = [
  {
    ObservationOccuranceId: '125D1A61-B97A-438A-86EC-B9A26EEA0310',
    observationLaunchDateTime: '2023-01-02T11:11:39',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
  {
    ObservationOccuranceId: '0008008A-AEE6-4CD7-AFD1-0B77334BC971',
    observationLaunchDateTime: '2023-01-02T11:11:14',
    Source: 'Portal',
    CategoryName: 'Observations',
    Name: 'test1',
    description: '',
    ObservationId: 519190,
    Status: '',
    EventType: '',
    observation: 'observation',
    RecordedBy: 'Test Account 3',
    CustomerName: 'Test Account 3',
    isEditable: false,
    isSystemForm: false,
    isMostRecent: true,
    isRuleApplicable: false,
  },
];
