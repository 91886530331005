import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import MozzazIcon from '../../../assets/img/mozzaz_icon.png';
export function LoginInfo() {
    return (
        <div>
            {/* <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ marginTop: 2 }}
            >
                <Link href="/resetpassword" color="primary" sx={{ fontSize: "20px", fontFamily: "ProximaNovaSoftSemibold" }}>
                    Forgot password?
                </Link>
            </Typography> */}

            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ marginTop: 4, fontSize: "18px", fontFamily: "ProximaNovaSoftMedium" }}
            >
                {'By using this app you agree to our'}
            </Typography>
            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ fontSize: "18px", fontFamily: "ProximaNovaMedium" }}
            >
                <Link color="primary" target='_blank' href="https://www.mozzaz.com/terms-of-use">
                    Terms of Use
                </Link>
                {' and '}
                <Link color="primary" target='_blank' href="https://www.mozzaz.com/privacy-policy">
                    Privacy Policy
                </Link>
            </Typography>
            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ marginTop: 4, fontSize: "18px", fontFamily: "ProximaNovaSoftMedium" }}
            >
                <Stack title='Powered By Mozzaz' direction="row" alignItems="center" justifyContent="center" gap={0}>
                    {'Powered By '}
                    <img src={MozzazIcon} style={{ width: "44px" }} alt="Mozzaz Care Tools"></img>
                </Stack>
            </Typography>
        </div>
    )
}