import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import Box from '@mui/material/Box';
import RenderSystemEmailItem from './renderSystemEmailItem';

const RenderSystemEmailDefinition = (props: any) => {
  useEffect(() => {}, []);

  return (
    <IntlProvider messages={{}} locale="de" defaultLocale="en">
      <Box
        component="div"
        sx={{
          display: 'flex',
          p: 1,
          flexDirection: 'column',
        }}
      >
        <Box>
          <h3>{props.headerText}</h3>
          {props.systemEmail.map((se: any, index: any) => (
            <RenderSystemEmailItem
              currentView={props.currentView}
              systemEmail={props.systemEmail}
              systemEmailData={props.systemEmailData}
              setSystemEmailData={props.setSystemEmailData}
              index={index}
            />
          ))}
        </Box>
      </Box>
    </IntlProvider>
  );
};

export default React.memo(RenderSystemEmailDefinition);
