import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../../data/model/user'

const initialState = { };

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    updateUser(state, action: PayloadAction<User>) {
      return { ...state, ...action.payload };
    },
    cleanUser(state) {
      return { };
    }
  }
})

export const { actions, reducer } = userSlice;