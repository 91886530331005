import axios from 'axios';

import { BACKEND_ENDPOINT } from '../config/Constant';
import { requestTemplate } from './RequestTemplate';
import { getState, dispatch, actions } from '../store';
import { LoginData, LoginState } from '../store/slices/login/type';
import { handleExpiredToken } from '../services/AuthAPIService';

export async function organizationList() {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'customertoolorganizationlist',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: session.sessionData.CustomerId,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  console.log('req', req, 'response', data);
  handleExpiredToken(data);

  if (!data || data.err) {
    // console.log(data.err);
    return;
  }
  return data;
}

export async function addOrganization(params: any) {
  // console.log('addOrganization, params: ', params);

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  if (params.organizationName.trim().length == 0)
    return {};

  let req = {
    API_NAME: 'mozzazpstooladdorganization',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: session.sessionData.CustomerId,
      OrganizationName: params.organizationName,
      ExpirePassword: params.expirePassword,
      PasswordLife: Number(params.passwordLife),
      PasswordExpiryNotification: params.passwordExpiryNotification,
      PasswordChangeReminderDays: Number(params.passwordChangeReminderDays),
      IsParentOrg: !params.hasParent,
      ParentOrgId: params.parentOrganization,
      RPMService: params.rpmService,
      NutritionService: params.nutritionService,
      SystemEmailData: params.systemEmailData,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  handleExpiredToken(data);

  // console.log('req', req, 'response', data);

  if (!data || data.err) {
    // console.log(data.err);
    return;
  }
  return data;
}

export async function getOrganization(params: any) {
  // console.log('getOrganization, params: ', params);

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'mozzazpstoolgetorganization',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: session.sessionData.CustomerId,
      ORGID: params.ORGID,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  handleExpiredToken(data);

  // console.log('req', req, 'response', data);

  if (!data || data.err) {
    // console.log(data.err);
    return;
  }
  return data;
}

export async function updateOrganization(params: any) {
  // console.log('updateOrganization, params: ', params);

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'mozzazpstoolupdateorganization',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: session.sessionData.CustomerId,
      OrganizationName: params.organizationName,
      ExpirePassword: params.expirePassword,
      PasswordLife: Number(params.passwordLife),
      PasswordLifeDuration: Number(params.passwordLifeDuration),
      PasswordExpiryNotification: params.passwordExpiryNotification,
      PasswordChangeReminderDays: Number(params.passwordChangeReminderDays),
      PasswordChangeReminderDaysDuration: Number(params.passwordChangeReminderDaysDuration),
      IsParentOrg: !params.hasParent,
      ParentOrgId: params.hasParent ? params.parentOrganization : params.ORGID,
      RPMService: params.rpmService,
      NutritionService: params.nutritionService,
      ORGID: params.ORGID,
      SystemEmailData: params.systemEmailData,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  console.log('req', req, 'response', data);
  handleExpiredToken(data);

  if (!data || data.err) {
    // console.log(data.err);
    return;
  }
  return data;
}

export async function getSystemEmailDefinition() {
  // console.log('SystemEmailDefinition');

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'mozzazpstoolSystemEmailDefinition',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: session.sessionData.CustomerId,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  handleExpiredToken(data);

  // console.log('req', req, 'response', data);

  if (!data || data.err) {
    // console.log(data.err);
    return;
  }
  return data;
}
