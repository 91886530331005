import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export function ForgotPasswordInfo() {
    return (
        <Box>
            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ marginTop: 2, fontSize: "22px", fontFamily: "proximaNovaSoftSemibold", color: " custom_dark_grey.main" }}
            >
                Forgot Password?
            </Typography>

            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ marginTop: 2, fontSize: "18px", fontFamily: "ProximaNovaSoftMedium", color: " custom_dark_grey.main" }}
            >
                Enter your username and email to get a link to reset your
                password.            
            </Typography>
        </Box>
    )
}