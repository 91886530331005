import { createTheme } from '@mui/material/styles';
import proximaNovaRegular from '../assets/fonts/ProximaNova-Regular.woff';
import proximaNovaSemibold from '../assets/fonts/ProximaNova-Semibold.woff';
import proximaNovaMedium from "../assets/fonts/ProximaNova-Medium.woff";
import proximaNovaSoftSemibold from '../assets/fonts/ProximaNovaSoft-Semibold.woff';
import proximaNovaSoftMedium from '../assets/fonts/ProximaNovaSoft-Medium.woff';
import proximaNovaSoftBold from '../assets/fonts/ProximaNovaSoft-Bold.woff';
import proximaNovaSoftRegular from '../assets/fonts/ProximaNovaSoft-Regular.woff';
export const MozzazDefaultTheme = createTheme({
  typography: {
    fontFamily: 'ProximaNova, ProximaNovaMedium, ProximaNovaSemibold, ProximaNovaSoftSemibold, ProximaNovaSoftBold, ProximaNovaSoftMedium, ProximaNovaSoftRegular',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { textTransform: 'none' },
          },
      },
      MuiCssBaseline: {
        styleOverrides: `
          @font-face 
            {
            font-family: 'ProximaNova';
            font-style: normal;
            font-display: swap;
            src: local('ProximaNova'), local('ProximaNova-Regular'), url(${proximaNovaRegular}) format('woff');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
          @font-face 
            {
            font-family: 'ProximaNovaMedium';
            font-style: normal;
            font-display: swap;
            src: local('ProximaNovaMedium'), local('ProximaNova-Regular'), url(${proximaNovaMedium}) format('woff');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
          @font-face {
            font-family: 'ProximaNovaSemibold';
            font-style: normal;
            font-display: swap;
            src: local('ProximaNovaSemibold'), local('ProximaNova-Semibold'), url(${proximaNovaSemibold}) format('woff');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
          @font-face {
            font-family: 'ProximaNovaSoftSemibold';
            font-style: normal;
            font-display: swap;
            src: local('ProximaNovaSoftSemibold'), local('ProximaNovaSoft-Semibold'), url(${proximaNovaSoftSemibold}) format('woff');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
          @font-face {
            font-family: 'ProximaNovaSoftMedium';
            font-style: normal;
            font-display: swap;
            src: local('ProximaNovaSoftMedium'), local('ProximaNovaSoft-Medium'), url(${proximaNovaSoftMedium}) format('woff');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
          @font-face {
            font-family: 'ProximaNovaSoftBold';
            font-style: normal;
            font-display: swap;
            src: local('ProximaNovaSoftBold'), local('ProximaNovaSoft-Bold'), url(${proximaNovaSoftBold}) format('woff');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
          @font-face {
            font-family: 'ProximaNovaSoftRegular';
            font-style: normal;
            font-display: swap;
            src: local('ProximaNovaSoftRegular'), local('ProximaNovaSoft-Regular'), url(${proximaNovaSoftRegular}) format('woff');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
        
        `,
    },
  },
  
  palette: {
    primary: {
      main: '#0074BF',
    },
    secondary: {
      main: '#004579',
    },
    error: {
      main: '#E00000',
    },
    warning: {
      main: '#ED6C02',
    },
    success: {
      main: '#ED6C02',
    },
    custom_mozzaz_blue: {
      main: '#ED6C02',
    },
    custom_light: {
      main: '#C5E8F6',
    },
    custom_nav_background: {
      main: '#F5F5F5',
    },
    custom_light_grey: {
      main: '#AEAEAE',
    },
    custom_medium_grey: {
      main: '#767676',
    },
    custom_grey: {
      main: '#58595B',
    },
    custom_dark_grey: {
      main: '#2E2E2E',
    },
    custom_2nd_light_grey: {
      main: '#CCCCCC',
    },
    very_high_or_low: {
      main: '#E00000'
    },
    high_or_low: {
      main: '#ED6C02'
    },
    mildly_high_or_low: {
      main: '#E2A100'
    },
    regular: {
      main: '#008507'
    },
    custom_white: {
      main : '#FFFFFF'
    }
  },
});