import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import AddIcon from "@mui/icons-material/Add";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { useNavigate } from "react-router-dom";
import { getFormList } from "../../../services/FormAPIService";
import { Form } from "../../../data/model/form";
import { useLoading } from "../../../context/loading.context";

export const FormList = () => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [searchedText, setSearchedText] = useState("");
  const [formList, setFormList] = useState<Form[]>([]);
  const {loading, setLoading} = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFormList();
  },[]);

  const fetchFormList = async () => {
    setLoading(true);
    const formList = await getFormList();
    setFormList(formList.Data.Forms)
    setLoading(false);

  }

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setSelectedCategory(Number.parseInt(event.target.value));
  };

  const handleSearchInput = (input : any) => {
    setSearchedText(input.target.value)
  }

  const getFormRow = (form : Form) => {
    const onHistoryClicked = (form: any) => {
      navigate("/patients/details/forms/history")
    }

    const onAddClicked = (form: any) => {
      navigate("/patients/details/forms/add")
    }

    return (
      <ListItem
              key={form.FormGUID}
              sx={{ p: 0, m: 0, borderTop: 1, borderColor: "custom_2nd_light_grey.main" }}
            >
              <Grid container sx={{ p: 0, m: 0 }}>
                <Grid item xs={5} md={5} sx={{ p: 0, m: 0 }}>
                  <Box component="p" sx={{ textOverflow: "ellipsis", whiteSpace:"nowrap", overflow:"hidden", color:"custom_dark_grey.main", fontSize:"20px"}}>
                    {form.FormName}
                  </Box>
                </Grid>
                <Grid item xs={3} md={3} sx={{ p: 0, m: 0 }}>
                  <Box component="p" sx={{ textOverflow: "ellipsis", whiteSpace:"nowrap", overflow:"hidden", color:"custom_dark_grey.main", fontSize:"20px"}}>
                    {form.CategoryName}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  sx={{ p: 0, m: 0, display: "flex", justifyContent: "center" }}
                >
                  <IconButton type="button" aria-label="Hisotry" sx={{ p: 0, fontSize: "20px"}} onClick={() => {
                    onHistoryClicked(form);
                  }}>
                    <ManageSearchIcon color="primary"/>
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  sx={{ p: 0, m: 0, display: "flex", justifyContent: "center" }}
                >
                  <IconButton
                    type="button"
                    aria-label="Add an entry"
                    sx={{ p: 0, fontSize: "20px" }}
                    onClick={() => {
                      onAddClicked(form);
                    }}
                  >
                    <AddIcon color="primary" />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
    )
  }

  return (
    <IntlProvider messages={{}} locale="de" defaultLocale="en">
      <Box component="div"
        sx={{ display: "flex", p: 1, flexDirection: "column", paddingLeft:5, paddingRight:5  }}>
        <Grid container spacing={2} sx={{ width: "100% !important" }}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 40,
              }}
            >
              <InputBase
                sx={{ m: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "Search from list of forms" }}
                onInputCapture={handleSearchInput}
              />
              <IconButton type="button" aria-label="search" sx={{ p: 0 }}>
                <SearchIcon color="primary" />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl size="small" sx={{ width: "100%" }}>
              <Select
                labelId="category-select"
                id="category-select"
                value={selectedCategory + ""}
                placeholder="Select Caegory"
                onChange={handleCategoryChange}
              >
                <MenuItem value={0}>All Category</MenuItem>
                {sampleCategories.map((category) => {
                  return (
                    <MenuItem value={category.CategoryId}>
                      {category.Name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container sx={{ p: 0, m: 0, width: "100% !important" }}>
          <Grid item xs={5} md={5} sx={{ p: 0, m: 0 }}>
            <Box component="p" sx={{ textOverflow: "ellipsis", overflow:"hidden", color:"custom_grey.main", fontSize:"20px", fontFamily:"ProximaNovaSemibold"}}>
              Name
            </Box>
          </Grid>
          <Grid item xs={3} md={3} sx={{ p: 0, m: 0 }}>
            <Box component="p" sx={{ textOverflow: "ellipsis", overflow:"hidden", color:"custom_grey.main", fontSize:"20px", fontFamily:"ProximaNovaSemibold"}}>
              Category
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            sx={{ p: 0, m: 0, display: "flex", justifyContent: "center" }}
          >
            <Box component="p" sx={{ textOverflow: "ellipsis", overflow:"hidden", color:"custom_grey.main", fontSize:"20px", fontFamily:"ProximaNovaSemibold"}}>
              History
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            sx={{ p: 0, m: 0, display: "flex", justifyContent: "center" }}
          >
            <Box component="p" sx={{ textOverflow: "ellipsis", overflow:"hidden", whiteSpace:"nowrap", color:"custom_grey.main", fontSize:"20px", fontFamily:"ProximaNovaSemibold"}}>
              Add an entry
            </Box>
          </Grid>
        </Grid>
        <List sx={{ width: "100%" }}>
          {formList.map((form : Form) => {
            if((selectedCategory === 0 || selectedCategory === form.CategoryId) && form.FormName.includes(searchedText) )
              return getFormRow(form);
          })}
        </List>
      </Box>
    </IntlProvider>
  );
};

const sampleCategories = [
  { CategoryId: 1, Name: "Activity Forms" },
  { CategoryId: 2, Name: "Observations" },
  { CategoryId: 6, Name: "Care Plan" },
  { CategoryId: 7, Name: "Progress Notes" },
  { CategoryId: 8, Name: "Self Assessments" },
  { CategoryId: 9, Name: "Clinical Assessments" },
  { CategoryId: 10, Name: "Caregiver Assessments" },
  { CategoryId: 11, Name: "Check-ins" },
  { CategoryId: 12, Name: "Notes" },
];