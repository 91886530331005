import axios from 'axios';

import { BACKEND_ENDPOINT } from '../config/Constant';
import { requestTemplate } from './RequestTemplate';
import { getState, dispatch, actions } from '../store';
import { LoginData, LoginState } from '../store/slices/login/type';
import { handleExpiredToken } from '../services/AuthAPIService';

export async function roleList() {
  console.log('roleList');

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }
  // MozzazPSToolRoleList
  let req = {
    API_NAME: 'mozzazpstoolrolelist',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: session.sessionData.CustomerId,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  console.log('req', req, 'response', data);
  handleExpiredToken(data);

  if (!data || data.err) {
    // console.log(data.err);
    return;
  }
  return data;
}

export async function searchByUsernameService(params: any) {
  console.log('searchByUsername, params: ', params);

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'customertoolsearchByUsername',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: session.sessionData.CustomerId,
      Username: params.Username,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  console.log('req', req, 'response', data);
  handleExpiredToken(data);

  if (!data || data.err) {
    // console.log(data.err);
    return;
  }
  return data;
}

export async function updateCustomer(params: any) {
  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'CustomerToolMoveAndUpdateCustomerRole',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: params.CustomerId,
      RoleId: params.RoleId,
      ORGID: params.ORGID,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  console.log('req', req, 'response', data);
  handleExpiredToken(data);

  if (!data || data.err) {
    console.log(data.err);
    return;
  }
  return data;
}
