import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { withStyles } from '@mui/material/styles';
import { Column } from './table';
import Button from '@mui/material/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { convertToLocal } from '../../utils/datetime';
import Avatar from '@mui/material/Avatar';

export interface TableBodyProps {
  columns: Column[];
  tableData: any;
  onRowClicked: Function;
}

const CustomTableBody = ({ columns, tableData, onRowClicked }: TableBodyProps) => {

  const getValue = (column: Column, data: any) => {
    const value = data[column.accessor] ? data[column.accessor] : 0;
    switch (column.type) {
      case 'date':
        return convertToLocal(value);
      case 'image':
        const fallback = column.imageFallBack ? data[column.imageFallBack] : '';
        return <Avatar sx={{ bgcolor: 'primary.main' }} alt={fallback} src={`${value}`} />;
      case 'button':
        return <Button>b {value}</Button>;
      default:
        return value;
    }
  };

  const uuidv4 =()=> {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  const getActionButton = (column: Column, data: any) => {
    switch (column.action) {
      case 'view':
        return (
          <Button
            sx={{
              fontSize: 14,
              fontFamily: 'ProximaNovaMedium',
              textTransform: 'none',
              lineHeight: 1,
              p: 0,
            }}
            variant="text"
            startIcon={<VisibilityOutlinedIcon color="primary" />}
            onClick={() => {
              if (column.actionEvent) column.actionEvent(data);
            }}
          >
            View
          </Button>
        );
      case 'edit':
        return (
          <Button
            sx={{
              fontSize: 14,
              fontFamily: 'ProximaNovaMedium',
              textTransform: 'none',
              lineHeight: 1,
              p: 0,
            }}
            variant="text"
            startIcon={<EditIcon color="primary" />}
            onClick={() => {
              if (column.actionEvent) column.actionEvent(data);
            }}
          >
            Edit
          </Button>
        );
    }
  };

  return (
    <tbody>
      {tableData.map((data: any) => {
          // console.log(data);

          return (
          <TableRow
            key={data.ORGID}
            hover={true}
            // onClick={() => {
            //   onRowClicked(data);
            // }}
          >
            {columns.map((column: any) => {
              // console.log(column, data);
              
              return (
                <TableCell
                  key={uuidv4().substring(0, 4)}
                  sx={{
                    color: 'custom_dark_grey.main',
                    fontFamily: 'ProximaNova',
                    fontSize: 18,
                  }}
                  style={{ padding: 10 }}
                >
                  {column.action === undefined ? getValue(column, data) : getActionButton(column, data)}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </tbody>
  );
};

export default CustomTableBody;
