export interface IPopUpMessage {
    title: string;
    message: string;
    primaryBtnText: string;
    primaryBtnEvent?: Function;
    secondaryBtnText?: string;
    secondaryBtnEvent?: Function;
}

export const AlertMessages = {
    login: {
        accessDenied: {
            title: "Access Denied",
            message: "Permission to access your account was not provided.",
            primaryBtnText: "OK",
            secondaryBtnText: undefined
        },
        accountDeleted: {
            title: "Invalid Username or Password",
            message: "If you have any questions, please contact your administrator.",
            primaryBtnText: "OK",
            secondaryBtnText: undefined
        },
        accountDeactivated: {
            title: "Invalid Username or Password",
            message: "If you have any questions, please contact your administrator.",
            primaryBtnText: "OK",
            secondaryBtnText: undefined
        },
        accountWillBeLocked: {
            title: "Incorrect Username or Password",
            message: `Your account will be locked after x more incorrect attempt(s).`,
            primaryBtnText: "OK",
            secondaryBtnText: undefined
        },
        accountLocked: {
            title: "Incorrect Username or Password.",
            message: "Your account is locked.",
            primaryBtnText: "OK",
            secondaryBtnText: undefined
        },
        noInternet: {
            title: "No Internet Connection",
            message: "Please check your connection and try again.",
            primaryBtnText: "OK",
            secondaryBtnText: undefined
        },
        permissionDenied: {
            title: "Permission Denied",
            message: "Please contact your administrator.",
            primaryBtnText: "OK",
            secondaryBtnText: undefined
        },
        signInFailed: {
            title: "Sign In Failed",
            message: "Please try again or contact your administrator.",
            primaryBtnText: "OK",
            secondaryBtnText: undefined
        },
    },
    form: {
        required: {
            title: "Required Field(s)",
            message: "",
            primaryBtnText: "Ok"
        },

        exit: {
            title: "Exit",
            message: "Any changes you made will be lost.",
            primaryBtnText: "Cancel",
            secondaryBtnText: "Exit"
        }
    },
    signout: {
        title: "Sign Out",
        message: "Are you sure you want to sign out?",
        primaryBtnText: "Sign Out",
        secondaryBtnText: "Cancel"
    },
    resetPassword: {
        emptyError: {
            title: "Error",
            message: "Please complete all of the fields.",
            primaryBtnText: "OK",
            secondaryBtnText: ""
        },
        invalidError: {
            title: "Error",
            message: "Please enter a new valid password",
            primaryBtnText: "OK",
            secondaryBtnText: ""
        },
        unmatchedError: {
            title: "Error",
            message: "Your new passwords do not match. Please try again.",
            primaryBtnText: "OK",
            secondaryBtnText: ""
        },
        success: {
            title: "Success",
            message: "Your password has been successfully changed.",
            primaryBtnText: "OK",
            secondaryBtnText: ""
        },
        failure: {
            title: "Error",
            message: "An error occured while chaging password.Please try again.",
            primaryBtnText: "OK",
            secondaryBtnText: ""
        },
    },
}