import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import {
  IntlProvider,
  FormattedDate,
  FormattedNumber,
  FormattedPlural,
} from 'react-intl';
import { LoginLogo, LoginForm, LoginInfo } from '../features/authentication';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/type';
import { LoginState } from '../store/slices/login/type';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    custom_light: true;
  }
}
export function Login() {
  const { t } = useTranslation();
  const loginState: LoginState = useSelector(
    (state: RootState) => state.loginReducer
  );

  return (
    <IntlProvider messages={{}} locale="de" defaultLocale="en">
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          marginTop: 17,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            marginTop: 9,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '350px',
          }}
        >
          <LoginLogo />
          <LoginForm />
        </Box>
        <LoginInfo />
      </Container>
    </IntlProvider>
  );
}
