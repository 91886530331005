import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import RenderSystemEmailDefinition from './renderSystemEmailDefinition';

const RenderOrganization = (props: any) => {
  useEffect(() => {}, []);

  function renderMenuItems(array: any) {
    if (!array) return;

    let render = [];
    for (let i = 0; i < array.length; i++) render.push(<MenuItem value={array[i].ORGID}>{array[i].OrganizationName}</MenuItem>);
    return render;
  }

  function readOnly(name: string = '') {
    // if (name == 'OrganizationName') return props.currentView == 'add' ? false : true;
    return props.currentView == 'view' ? true : false;
  }

  return (
    <IntlProvider messages={{}} locale="de" defaultLocale="en">
      <Box
        component="div"
        sx={{
          display: 'flex',
          p: 1,
          flexDirection: 'column',
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <Box>
          <h3>{props.headerText}</h3>

          <Divider style={{ marginBottom: 12 }} />

          <Box style={{ padding: 12, marginTop: 12, border: '1px rgba(0, 0, 0, 0.23) solid', borderRadius: 5 }}>
            <p style={{ margin: '0', paddingBottom: 2 }}>Organization Details:</p>

            <TextField
              inputProps={{ readOnly: readOnly('OrganizationName') }}
              fullWidth
              label="Organization Name"
              variant="outlined"
              value={props.organizationName}
              onChange={(event) => {
                props.setOrganizationName(event.target.value);
              }}
            />
            {props.organizationNameError && (
              <div style={{ color: 'red', marginTop: '5px' }}>
                <ErrorOutlineOutlinedIcon fontSize="small" style={{ fontSize: '1em' }} />
                <span style={{ fontSize: '12px', paddingLeft: '6px' }}>This organization name is already in use.</span>
              </div>
            )}

            <Grid item xs={12} sm={6} md={3} style={{ marginTop: 12 }}>
              <FormControlLabel
                checked={props.hasParent}
                control={<Checkbox disabled={readOnly()} />}
                label="Has Parent"
                labelPlacement="start"
                onChange={(event, value) => {
                  props.setHasParent(value);
                }}
              />

              <FormControl style={{ marginLeft: 12, width: 250, maxHeight: 333 }}>
                <InputLabel id="demo-simple-select-label">Parent Organization</InputLabel>
                <Select
                  inputProps={{ readOnly: readOnly() }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.parentOrganization}
                  label="Parent Organization"
                  onChange={(event) => {
                    props.setParentOrganization(event.target.value);
                  }}
                >
                  {renderMenuItems(props.ORGS)}
                </Select>
              </FormControl>
            </Grid>
          </Box>

          <Divider style={{ marginTop: 12 }} />

          <Box style={{ padding: 12, marginTop: 12, border: '1px rgba(0, 0, 0, 0.23) solid', borderRadius: 5 }}>
            <p style={{ margin: '0', paddingBottom: 2 }}>Police Configuration:</p>

            <Grid item xs={12} sm={6} md={3} style={{ marginTop: 12 }}>
              <FormControlLabel
                style={{ marginTop: 12 }}
                checked={props.expirePassword}
                control={<Checkbox disabled={readOnly()} />}
                label="Expire Password"
                labelPlacement="start"
                onChange={(event, value) => {
                  props.setExpirePassword(value);
                }}
              />
              <TextField
                inputProps={{ readOnly: readOnly() }}
                style={{ marginLeft: 12 }}
                label="Password Life"
                variant="outlined"
                value={props.passwordLife}
                onChange={(event) => {
                  var re = new RegExp('^[0-9]*$');
                  if (re.test(event.target.value)) props.setPasswordLife(event.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} style={{ marginTop: 12 }}>
              <FormControlLabel
                style={{ marginTop: 12 }}
                checked={props.passwordExpiryNotification}
                control={<Checkbox disabled={readOnly()} />}
                label="Password Expiry Notification"
                labelPlacement="start"
                onChange={(event, value) => {
                  props.setPasswordExpiryNotification(value);
                }}
              />
              <TextField
                inputProps={{ readOnly: readOnly() }}
                style={{ marginLeft: 12 }}
                label="Password Change Reminder Days"
                variant="outlined"
                value={props.passwordChangeReminderDays}
                onChange={(event) => {
                  var re = new RegExp('^[0-9]*$');
                  if (re.test(event.target.value)) props.setPasswordChangeReminderDays(event.target.value);
                }}
              />
            </Grid>

            <FormControlLabel
              style={{ marginTop: 12 }}
              checked={props.rpmService}
              control={<Checkbox disabled={readOnly()} />}
              label="RPM Service"
              labelPlacement="start"
              onChange={(event, value) => {
                props.setRpmService(value);
              }}
            />

            <FormControlLabel
              checked={props.nutritionService}
              style={{ marginTop: 12 }}
              control={<Checkbox disabled={readOnly()} />}
              label="Nutrition Service"
              labelPlacement="start"
              onChange={(event, value) => {
                props.setNutritionService(value);
              }}
            />
          </Box>

          <Divider style={{ marginTop: 12 }} />
        </Box>

        <Box style={{ marginTop: 12, border: '1px rgba(0, 0, 0, 0.23) solid', borderRadius: 5 }}>
          <p style={{ margin: '0', padding: '12px 0 0 12px' }}>System Email Definition:</p>
          <RenderSystemEmailDefinition
            currentView={props.currentView}
            systemEmail={props.systemEmail}
            systemEmailData={props.systemEmailData}
            setSystemEmailData={props.setSystemEmailData}
          />
        </Box>

        <Button
          sx={{
            marginTop: 3,
            height: '40px',
            fontSize: '20px',
            fontFamily: 'ProximaNovaSoftSemibold',
          }}
          variant="contained"
          onClick={props.onClick}
        >
          {props.buttonText}
        </Button>
      </Box>
    </IntlProvider>
  );
};

export default React.memo(RenderOrganization);
