interface FormAnswers {
   AnswerId: number, AnswerText: string, OriginAnswerId: number,
}
interface FormQuestion {
    QuestionId: number,
    QuestionText: string,
    RichText:
     string,
    QuestionType: string,
    Required: boolean,
    Answers: FormAnswers[] | [],
    OriginQuestionId: number,
    textResponse: string,
    answerText: string,
    answerId: number
  }

 function reportMap(data:any) : FormQuestion {
   const formQuestion: FormQuestion = {
    QuestionId: data.QuestionId,
    QuestionText: data.QuestionText,
    RichText: data.RichText,
    QuestionType: data.QuestionType,
    Required: data.Required,
    Answers: data.Answers,
    OriginQuestionId: data.OriginQuestionId,
    textResponse: data.textResponse,
    answerText: data.answerText,
    answerId: data.answerId
  }

  return formQuestion;
}

export { reportMap };
export type { FormQuestion, FormAnswers };
  