import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { FormAnswers } from "../../data/model/formQuestion";
import { FormInputProps } from "./FormInputProps";

const options = [
  {
    label: "Checkbox Option 1",
    value: "1",
  },
  {
    label: "Checkbox Option 2",
    value: "2",
  },
];

export const FormInputCheckbox: React.FC<FormInputProps> = ({
  name,
  control,
  setValue,
  question
}) => {
  const [selectedItems, setSelectedItems] = useState<any>([]);

  const handleSelect = (value: any) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item: any) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems: any) => [...prevItems, value]);
    }
  };

  useEffect(() => {
    setValue(name, selectedItems);
  }, [selectedItems]);

  return (
    <FormControl size={"small"} variant={"outlined"}>
      <Stack direction="column">
        {question?.Answers.map((answer: FormAnswers) => {
          return (
            <FormControlLabel
              control={
                <Controller
                  name={name}
                  render={() => {
                    return (
                      <Checkbox
                        sx={{
                          color: "#0074BF",
                          '&.Mui-checked': {
                            color: "#0074BF",
                          },
                        }}
                        checked={selectedItems.includes(answer.AnswerId)}
                        onChange={() => handleSelect(answer.AnswerId)}
                      />
                    );
                  }}
                  control={control}
                />
              }
              label={answer.AnswerText}
              key={answer.AnswerId}
            />
          );
        })}
      </Stack>
    </FormControl>
  );
};