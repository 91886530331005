import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { SideBar } from './features/drawer';
import { Organization } from './screens/Organization';
import { FormHistory, FormList } from './features/forms';
import Toolbar from '@mui/material/Toolbar';
import { Login } from './screens/Login';
import { Dashboard } from './screens/Dashboard';
import { Customer } from './screens/Customer';
import { ForgotPassword } from './screens/ForgotPassword';
import { ResetPassword } from './screens/ResetPassword';
import { AddFormEntry } from './features/forms/components/addFormEntry';
import { useSelector } from 'react-redux';
import { LoadingProvider } from './context/loading.context';
import { Profile } from './screens/Profile';
import { PatientDetails } from './features/patinetDetails';
import { Devices } from './features/devices';
import React, { useEffect, useState } from 'react';
import { DrawerWidth } from './components/Constant';

function App() {
  const [width, setWidth] = React.useState(DrawerWidth);

  const state = useSelector((s: any) => {
    return (s as any).loginReducer;
  });
  return (
    <div style={{ display: 'flex' }}>
      <LoadingProvider>
        <Router>
          {state.isLoggedIn ? (
            <>
              <SideBar setWidth={setWidth} />
              <div style={{ height: '100vh', width: '100%', flexGrow: 1 }}>
                <Toolbar />
                <div style={{ height: 'calc(100% - 64px)' }}>
                  <Routes>
                    <Route path="/" element={<Customer width={width} />}></Route>
                    <Route path="/dashboard" element={<Dashboard width={width} />}></Route>
                    <Route path="/organization" element={<Organization width={width} />}></Route>
                    <Route path='/accountTransfer' element={<Customer width={width} />}></Route>
                    {/* <Route path="/insight" element={<Insight />}></Route> */}
                    <Route path="/patients/details" element={<PatientDetails />}>
                      <Route index element={<Profile />} />
                      <Route path="profile" element={<Profile />}></Route>
                      <Route path="*" element={<Profile />}></Route>
                      <Route path="devices" element={<Devices />}></Route>
                      <Route path="forms" element={<FormList />}>
                        <Route path="history" element={<FormHistory />}></Route>
                        <Route path="add" element={<AddFormEntry />}></Route>
                      </Route>
                    </Route>
                  </Routes>
                </div>
              </div>
            </>
          ) : (
            <Routes>
              <Route path="/" element={<Login />}></Route>
              <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
              <Route path="/resetpassword" element={<ResetPassword />}></Route>
              <Route path="*" element={<Navigate to="/" replace />}></Route>
            </Routes>
          )}
        </Router>
      </LoadingProvider>
    </div>
  );
}

export default App;
