import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';

export const FormInputDate = ({ name, control, question, isEdit }: FormInputProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        defaultValue={isEdit? question?.textResponse : undefined}
        render={({ field, fieldState, formState }) => (
          <DatePicker
            disableMaskedInput
            inputFormat='MMM dd, yyyy'
            views={['year', 'month', 'day']}
            value={field.value || null}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            renderInput={(params) => <TextField size='small' {...params} inputProps={{
                ...params.inputProps,
                placeholder: "Select Date"
              }}/>}
          />
        )}
      />
    </LocalizationProvider>
  );
};
