import * as React from 'react';
import Box from '@mui/material/Box';
import { NavigationBar } from "../../../components/NavigationBar";
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import profileImg from '../../../assets/img/profile1.png';
import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const response =
{
    "IsSuccess": true,
    "Data": {
        "FirstName": "Cotton Eye",
        "LastName": "Joe",
        "Username": "MaxnerAdmin",
        "EmailId": "michael.maxner@mozzaz.com",
        "ContactNo": "90210",
        "dtDob": "2000-01-13T10:00:00Z",
        "Address1": "99 Roaden St",
        "City": "Kitchener",
        "State": "Ontario",
        "Country": "Canada",
        "PostalCode": "A1B 2C3",
        "UserType": "P",
        "BusinessPhoneNo": "111",
        "Gender": "M",
        "IsRPMUser": true,
        "usesNutrition": true
    }
}

const profileData: any = response.Data;
var order = ["FirstName", "LastName", "Username", "ContactNo", "EmailId", "dtDob", "Gender", "Address1", "City", "Country", "State", "PostalCode"];
const sortedData = order.reduce((accumulator: any, key: any) => {
    accumulator[key] = profileData[key];
    return accumulator;
}, {});
console.log(sortedData);

const tableData = Object.keys(sortedData).map(key =>
    <TableRow>
        <TableCell align="left" sx={{py:'15px !important', width: '400px',fontSize: '20px', fontFamily: 'ProximaNovaSoftSemibold', color: 'secondary.main' }} >
            {key}
        </TableCell>
        <TableCell sx={{py:'15px !important', color:'#2E2E2E', fontSize:'18px !important', fontFamily: "ProximaNovaMedium" }} >{sortedData[key]}</TableCell>
    </TableRow>
)
export function ViewProfile() {
    return (
        <Box>
            <Box sx={{ p: '24px'}}>
                <Box sx={{ maxWidth: 650, display: 'flex', justifyContent: 'space-between', py:'20px'  }}>
                    <Avatar sx={{width: '60px', height: '60px'}} alt="Harry Potter" src={profileImg} />
                    <Button sx={{width: '100px', height: '40px', fontSize: '20px', fontFamily: "ProximaNovaSoftSemibold"}} variant="contained"><EditOutlinedIcon sx={{pr: '3px'}}/>Edit</Button>
                </Box>
                <TableContainer component={Paper} sx={{ maxWidth: 650, boxShadow: 'none' }}>
                    <Table  aria-label="simple table">
                        <TableBody>
                            {tableData}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}