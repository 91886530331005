import 'react-toastify/dist/ReactToastify.css';
import { Icon, IconButton, InputAdornment, Typography } from '@mui/material';
import { IntlProvider } from 'react-intl';
import { organizationList } from '../../../services/OrganizationAPIService';
import { roleList, searchByUsernameService, updateCustomer } from '../../../services/CustomerAPIService';
import { ToastContainer, toast } from 'react-toastify';
import { useLoading } from '../../../context/loading.context';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBarMenu from '../../../components/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

//roles allowed
let arrayAllow = [1, 1117, 1185, 10];

const CustomerList = (props: any) => {
  let location = useLocation();

  const [userName, setUserName] = React.useState('user');
  const [firstName, setFirstName] = React.useState('first');
  const [lastName, setLastName] = React.useState('last');
  const [customerId, setCustomerId] = React.useState('customerId');
  const [roleId, setRoleId] = React.useState('roleId');

  const [currentOrgId, setCurrentOrgId] = React.useState(-1);
  const [currentOrgIdNew, setCurrentOrgIdNew] = React.useState(-1);
  const [currentRole, setCurrentRole] = React.useState(-1);

  const [ORGS, setORGS] = React.useState();
  const [roles, setRoles] = React.useState();

  const { loading, setLoading } = useLoading();
  const [update, setUpdate] = React.useState(false);

  const [searchByUserName, setSearchByUserName] = React.useState('');
  const [searchByUserNameSaved, setSearchByUserNameSaved] = React.useState('');

  //sub screens shown to user:
  //0: tip, 1: found, -1: not found, 2: completed transfer
  const [searchState, setSearchState] = React.useState(0);

  useEffect(() => {
    fetchCustomers();
  }, [location, update]);

  function renderMenuItems(array: any) {
    if (!array) return;

    let render = [];
    for (let i = 0; i < array.length; i++)
      //please select one
      if (array[i].ORGID == -1) {
        render.push(<MenuItem style={{ fontStyle: 'italic' }} value={array[i].ORGID || array[i].RoleId}>{array[i].OrganizationName || array[i].Name}</MenuItem>);
      }
      else
        render.push(<MenuItem value={array[i].ORGID || array[i].RoleId}>{array[i].OrganizationName || array[i].Name}</MenuItem>);
    return render;
  }

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const response = await organizationList();
      if (response.IsSuccess) {
        response.Data.Data.splice(0, 0, { ORGID: -1, OrganizationName: 'Please select one...' })
        setORGS(response.Data.Data);
      }

    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onClickSearch = async () => {
    if (searchByUserName.length == 0) return;

    setSearchState(0);//tip
    setSearchByUserNameSaved(searchByUserName);

    setLoading(true);

    let data = await searchByUsernameService({ Username: searchByUserName });
    //assign data to form
    if (data.IsSuccess) {
      setCustomerId(data.Data.CustomerId);
      setUserName(data.Data.Username);
      setFirstName(data.Data.FirstName);
      setLastName(data.Data.LastName);
      setCurrentOrgId(data.Data.ORGID);
      setCurrentOrgIdNew(-1);
      setSearchState(1);
      setLoading(false);
      return true;
    }
    setSearchState(-1);
    setLoading(false);
    return false;
  };

  const onClickSaveCustomer = async () => {
    setLoading(true);
    let res = await updateCustomer({ CustomerId: customerId, RoleId: currentRole, ORGID: currentOrgIdNew });
    if (res.IsSuccess) {
      toast('Success - Account transferred.', { type: 'success', hideProgressBar: true, position: 'bottom-center', });
      setSearchByUserName('');
      setSearchState(2);
    }
    else toast('Sorry, something went wrong!', { type: 'error' });
    setLoading(false);
  };

  function orgName(orgId: any) {
    let arr: any = ORGS;
    return arr?.find((item: any) => item.ORGID == orgId)?.OrganizationName;
  }

  return (
    <IntlProvider messages={{}} locale="de" defaultLocale="en">
      <AppBarMenu title="Account Transfer" open={true} width={props.props.width} />
      <Box
        component="div"
        sx={{
          display: 'flex',
          p: 1,
          flexDirection: 'column',
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 5,
        }}
      >
        <div>

          {/* Search box */}
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <TextField
              className='fontStyle padding'
              style={{ width: '40%' }}
              value={searchByUserName}
              fullWidth
              variant="outlined"
              onChange={(event) => {
                setSearchByUserName(event.target.value.trim());
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') onClickSearch();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      title='Search'
                      disabled={searchByUserName.length > 0 ? false : true}
                      color='primary'
                      onClick={() => {
                        onClickSearch();
                      }}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          {/* initial tip  */}
          {searchState == 0 && (
            <div><p className='help'>Type Username and click 'Search' or 'Enter'</p></div>
          )}

          {/* found user  */}
          {searchState == 1 && (
            <div>
              <div><p className='help'>Type Username and click 'Search' or 'Enter'</p></div>

              {/* 1st row */}

              <div style={{ paddingTop: 25, display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography className='fontStyle' variant="h6" display="block" gutterBottom color='primary'>
                    Username
                  </Typography >
                  <Typography className='fontStyle' style={{ paddingTop: 0 }} variant="h6" display="block" gutterBottom>
                    {userName}
                  </Typography>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography className='fontStyle' variant="h6" display="block" gutterBottom color='primary'>
                    First Name
                  </Typography >
                  <Typography className='fontStyle' style={{ paddingTop: 0 }} variant="h6" display="block" gutterBottom>
                    {firstName}
                  </Typography>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography className='fontStyle' variant="h6" display="block" gutterBottom color='primary'>
                    Last Name
                  </Typography >
                  <Typography className='fontStyle' style={{ paddingTop: 0 }} variant="h6" display="block" gutterBottom>
                    {lastName}
                  </Typography>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography className='fontStyle' variant="h6" display="block" gutterBottom color='primary'>
                    Customer Id
                  </Typography >
                  <Typography className='fontStyle' style={{ paddingTop: 0 }} variant="h6" display="block" gutterBottom>
                    {customerId}
                  </Typography>
                </div>

              </div>

              {/* to cover user is from Parent Org */}
              {orgName(currentOrgId) && <div>

                {/* 2nd row */}
                <div style={{ paddingTop: 25, display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography className='fontStyle' variant="h6" display="block" gutterBottom color='primary'>
                      Current Organization
                    </Typography >
                    <Typography className='fontStyle' style={{ paddingTop: 0 }} variant="h6" display="block" gutterBottom>
                      {orgName(currentOrgId)}
                    </Typography>
                  </div>

                </div>

                {/* 3rd row */}
                <div style={{ paddingTop: 25, display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>

                  <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                    <Typography className='fontStyle' variant="h6" display="block" gutterBottom color='primary'>
                      New Organization
                    </Typography >
                    <Select key='2' className='fontStyle dropdown' style={{ padding: '2px', borderRadius: '12px' }}
                      placeholder='Please select one'
                      value={currentOrgIdNew}
                      size='small'
                      onChange={(event) => {
                        setCurrentOrgIdNew(Number(event.target.value));
                      }}
                    >
                      {renderMenuItems(ORGS)}
                    </Select>
                  </div>
                </div>

                {/* Button */}
                <div style={{ paddingTop: 25, display: 'flex', alignItems: 'end', flexDirection: 'column' }}>
                  <Button
                    sx={{
                      marginTop: '15px',
                      height: '40px',
                      fontSize: '20px',
                      fontFamily: 'ProximaNovaSoftSemibold',
                    }}
                    variant="contained"
                    disabled={currentOrgIdNew == -1 || currentOrgId == currentOrgIdNew}
                    onClick={() => {
                      onClickSaveCustomer();
                    }}
                  >
                    Transfer User
                  </Button>
                </div>
              </div>}
            </div>
          )}

          {/* not found user  */}
          {searchState == -1 && (
            <div><p className='help'>Not User found. Check the Username</p></div>
          )}

          {/* after transferred tip  */}
          {searchState == 2 && (
            <div>
              <p className='help'>Success - User account has been transferred</p>
              <br />
              <p className='help'>Username:</p>
              <p className='help'>{searchByUserNameSaved}</p>
              <p className='help'>New Organization:</p>
              <p className='help'>{orgName(currentOrgIdNew)}</p>
              <br />
              <p className='help'>Type Username and click 'Search' or 'Enter'</p>
            </div>
          )}
        </div>
      </Box>
      <ToastContainer position="bottom-center" />
    </IntlProvider >
  );
};

export default React.memo(CustomerList);
