const CONFIG = {
  LOCAL: {
    BACKEND_ENDPOINT: "http://localhost:23334/restapi.ashx",
  },
  DEV: {
    BACKEND_ENDPOINT: "https://server-dev.mozzazcare.com/restapi.ashx",
  },
  QA: {
    BACKEND_ENDPOINT: "https://server-qa.mozzazcare.com/restapi.ashx",
  },
  STAGGING: {
    BACKEND_ENDPOINT: "https://server-staging.mozzazcare.com/restapi.ashx",
  },
  PROD: {
    BACKEND_ENDPOINT: "https://server-prod.mozzazcare.com/restapi.ashx",
  },
};

export function config() {
  const ENV = process.env.REACT_APP_ENV || localStorage.getItem("Env") || "DEV";
  return CONFIG[ENV];
}