import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { FormAnswers } from "../../data/model/formQuestion";
import { FormInputProps } from "./FormInputProps";


export const FormInputDropdown: React.FC<FormInputProps> = ({
  name,
  control,
  question,
  isEdit
}) => {
  const generateSingleOptions = () => {
    return question?.Answers.map((answer: FormAnswers) => {
      return (
        <MenuItem key={answer.AnswerId} value={answer.AnswerId}>
          {answer.AnswerText}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl sx={{width : "50%"}} size="small">
      <Controller
        rules={{required:question?.Required}}
        defaultValue={isEdit ? (question?.answerId === 0 ? "" : question?.answerId) :""}
        render={({ field: { onChange, value } }) => (
          <Select onChange={onChange} value={value} placeholder="Select" defaultValue={""}>
            {generateSingleOptions()}
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};