import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { login } from '../../../services/AuthAPIService';
import { useLoading } from '../../../context/loading.context';
import './ForgotPassword.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { PopUp } from "../../../components/PopUp";
import { AlertMessages, IPopUpMessage } from '../../../data/popup-messages';
import { FlashOnRounded } from "@mui/icons-material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import { saveNewPassword } from '../../../services/AuthAPIService';

export function ResetPasswordForm() {
    const { loading, setLoading } = useLoading();
    const { t } = useTranslation();
    const [isLengthError, setLengthError] = useState<boolean>(false);
    const [isLowerCaseError, setLowerCaseError] = useState<boolean>(false);
    const [isUpperCaseError, setUpperCaseError] = useState<boolean>(false);
    const [isNumberError, setNumberError] = useState<boolean>(false);
    const [new_password, setNewPassword] = useState<string>("");
    const [confirm_password, setConfirmPassword] = useState<string>("");
    const [showPopup, setShowPopup] = useState(false);
    const [popupDetails, setPopupDetails] = useState<any>({
        title: "",
        message: "",
        primaryBtnText: "OK",
        secondaryBtnText: "",
        primaryBtnEvent: () => { setShowPopup(false) }
    });

    const [showLinkExpired, setshowLinkExpired] = useState<boolean>(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
    const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");

     const url = new URL(
        "https://mozzazcare.com/Resetpassword.Html?Auth=A581bfdf-396E-4Db0-8D34-582A6a2ac74c&Username=Srsproftest"
      );
    const auth = url.searchParams.get('Auth');
    const userName = url.searchParams.get('Username');

    console.log(auth)
    console.log(userName);

    const passwordCriterias = (newPassword: string, confirmPassword: string) => {
        if (newPassword !== "" || confirmPassword !== "") {
            return (
                <Box sx={{marginTop: 1}}>
                    {(isLowerCaseError || isUpperCaseError || isNumberError || isLengthError) && (<Box sx={{ fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "error.main", display: "flex" }}>
                        <div style={{ paddingRight: "10px" }}><ErrorOutlineIcon style={{ fill: "error.main" }} /></div>
                        <div>The chosen password does not meet all the Password criteria</div>
                    </Box>)}
                    {isUpperCaseError && (<Box sx={{ fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "error.main", display: "flex" }}>
                        <div style={{ paddingRight: "10px" }}><CancelOutlinedIcon style={{ fill: "error.main" }} /></div>
                        <div>At least one upper-case letter (A-Z)</div>
                    </Box>)}
                    {!isUpperCaseError && (<Box sx={{ fontSize: '18px', fontFamily: 'ProximaNovaMedium',color: "custom_dark_grey.main", display: "flex" }}>
                        <div style={{ paddingRight: "10px" }}><CheckCircleOutlineIcon style={{ fill: "#008507" }} /></div>
                        <div>At least one upper-case letter (A-Z)</div>
                    </Box>)}
                    {isLowerCaseError && (<Box sx={{ fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "error.main", display: "flex" }}>
                        <div style={{ paddingRight: "10px" }}><CancelOutlinedIcon style={{ fill: "error.main" }} /></div>
                        <div>At least one lower-case letter (a-z)</div>
                    </Box>)}
                    {!isLowerCaseError && (<Box sx={{ fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "custom_dark_grey.main", display: "flex" }}>
                        <div style={{ paddingRight: "10px" }}><CheckCircleOutlineIcon style={{ fill: "#008507" }} /></div>
                        <div>At least one lower-case letter (a-z)</div>
                    </Box>)}
                    {isNumberError && (<Box sx={{ fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "error.main", display: "flex" }}>
                        <div style={{ paddingRight: "10px" }}><CancelOutlinedIcon style={{ fill: "error.main" }} /></div>
                        <div>At least one number (0-9)</div>
                    </Box>)}
                    {!isNumberError && (<Box sx={{ fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "custom_dark_grey.main", display: "flex" }}>
                        <div style={{ paddingRight: "10px" }}><CheckCircleOutlineIcon style={{ fill: "#008507" }} /></div>
                        <div>At least one number (0-9)</div>
                    </Box>)}
                    {isLengthError && (<Box sx={{ fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "error.main", display: "flex" }}>
                        <div style={{ paddingRight: "10px" }}><CancelOutlinedIcon style={{ fill: "error.main" }} /></div>
                        <div>Length 8 characters</div>
                    </Box>)}
                    {!isLengthError && (<Box sx={{ fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "custom_dark_grey.main", display: "flex" }}>
                        <div style={{ paddingRight: "10px" }}><CheckCircleOutlineIcon style={{ fill: "#008507" }} /></div>
                        <div>Length 8 characters</div>
                    </Box>)}
                </Box>
            );
        }
    };

    useEffect(() => {
        if (new_password === "" && confirm_password === "") {
            return;
        }
        onChangeNewPass();
    }, [new_password, confirm_password]);

    const onChangeNewPass = () => {
        var patternLower = /^.*(?=.*[a-z]).*$/;
        var patternUpper = /^.*(?=.*[A-Z]).*$/;
        var patternNum = /^.*(?=.*?[0-9]).*$/;
        setLowerCaseError(!patternLower.test(new_password));
        setUpperCaseError(!patternUpper.test(new_password));
        setNumberError(!patternNum.test(new_password));
        new_password.length < 8 ? setLengthError(true) : setLengthError(false);
    };

    const onClickChangePass = async (e: any) => {
        setShowSuccessMsg(false);
        setShowErrorMsg(false);
        if (isUpperCaseError || isLowerCaseError || isLengthError || isNumberError) {
            setErrorMsg("Please enter a new valid password");
            setShowErrorMsg(true);
            return;
        }

        if (new_password.length === 0 || confirm_password.length === 0) {
            setErrorMsg("Please complete all of the fields.");
            setShowErrorMsg(true);
            return;
        }
        if (new_password === confirm_password) {
            setLoading(true);
            //const data: any = await saveNewPassword(new_password, auth);
            setLoading(false);
            console.log("call API");
        } else {
            setErrorMsg("Please try again, your passwords do not match.");
            setShowErrorMsg(true);
        }
    };

    return (
        <Box
            //component="form"
            //onSubmit={handleSubmit}
            //noValidate
            sx={{ mt: 4, width: '350px' }}
        >
            {!showLinkExpired && (<Box>
                <Typography
                    variant="body2"
                    color="custom_grey.main"
                    align="left"
                    sx={{
                        marginTop: 0,
                        fontSize: '18px',
                        fontFamily: "proximaNovaSoftSemibold",
                        color: `${(showErrorMsg) ? "error.main" : "custom_grey.main"}`
                    }}
                    //className={`${(isLowerCaseError || isUpperCaseError || isNumberError || isLengthError) ? "isError" : "regular"}`}
                >
                    {'New Password'}
                </Typography>
                <OutlinedInput
                    error={(isLowerCaseError || isUpperCaseError || isNumberError || isLengthError || showErrorMsg)}
                    size="small"
                    sx={{ marginTop: 1, borderRadius: "8px" }}
                    required
                    fullWidth
                    id="password"
                    label=""
                    name="password"
                    autoComplete="password"
                    autoFocus
                    inputProps={{ style: { fontSize: '18px' } }}
                    onChange={(e) => setNewPassword(e.target.value.trim())}
                />

                <Typography
                    variant="body2"
                    color="custom_grey.main"
                    align="left"
                    sx={{
                        marginTop: 1,
                        fontSize: '18px',
                        fontFamily: "proximaNovaSoftSemibold",
                        color: `${(showErrorMsg) ? "error.main" : "custom_grey.main"}`
                    }}
                >
                    {'Confirm New Password'}
                </Typography>
                <OutlinedInput
                    error={showErrorMsg}
                    size="small"
                    sx={{ marginTop: 1, borderRadius: "8px" }}
                    required
                    fullWidth
                    id="confirmpassword"
                    label=""
                    name="confirmpassword"
                    autoComplete="confirmpassword"
                    autoFocus
                    inputProps={{ style: { fontSize: '18px' } }}
                    onChange={(e) => setConfirmPassword(e.target.value.trim())}
                />
                {passwordCriterias(new_password, confirm_password)}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{
                        mt: 3,
                        mb: 2,
                        height: '40px',
                        fontSize: '20px',
                        fontFamily: "proximaNovaSoftSemibold",
                        borderRadius: '8px'
                    }}
                    onClick={(e: any) => onClickChangePass(e)}
                >
                    {t('Submit')}
                </Button>
                {showSuccessMsg && (<Box sx={{ marginTop: 1, fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "custom_dark_grey.main", display: "flex" }}>
                    <div style={{ height: "24px", alignSelf: "center", paddingRight: "10px", color: "regular.main" }}><CheckCircleOutlineIcon style={{ fill: "#008507" }} /></div>
                    <div>Your password has been successfully updated.</div>
                </Box>)}
                {showErrorMsg && (<Box sx={{ marginTop: 1, fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "error.main", display: "flex" }}>
                <div style={{ paddingRight: "10px" }}><ErrorOutlineIcon style={{ fill: "error.main" }} /></div>
                <div>{errorMsg}</div>
                </Box>)}
            </Box>)
            }
            {
                showPopup && (<PopUp
                    open={true}
                    header={popupDetails.title}
                    message={popupDetails.message}
                    primaryBtnText="OK"
                    secondaryBtnText=""
                    primaryBtnEvent={popupDetails.primaryBtnEvent ? popupDetails.primaryBtnEvent : () => { setShowPopup(false) }}
                    secondaryBtnEvent={() => {
                        setShowPopup(false);
                    }}
                ></PopUp>)
            }
            {
                showLinkExpired && (<Box sx={{ marginTop: 1, fontSize: '18px', fontFamily: 'ProximaNovaMedium', color: "custom_dark_grey.main", display: "flex" }}>
                    <div style={{ height: "24px", alignSelf: "center", paddingRight: "10px" }}><ErrorOutlineIcon style={{ fill: "error.main" }} /></div>
                    <div>The Password Reset link has expired, please reset your password again.</div>
                </Box>)
            }
        </Box >
    );
}
