import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { createContext, useContext, useState } from 'react';

interface ILoading {
  loading: boolean;
  setLoading: any;
}

function Loading() {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer * 2 }} open={true}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
}

const LoadingContext = createContext<ILoading>({
  loading: false,
  setLoading: null,
});

export function LoadingProvider(props: any) {
  const [loading, setLoading] = useState(false);
  const value = { loading, setLoading };
  return (
    <LoadingContext.Provider value={value}>
      {loading && <Loading />}
      {props.children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}
