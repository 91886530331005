import axios from 'axios';

import { BACKEND_ENDPOINT } from '../config/Constant';
import { requestTemplate } from './RequestTemplate';
import { getState, dispatch, actions } from '../store';
import { LoginData, LoginState } from '../store/slices/login/type';

export async function login(username: any, password: any) {
  console.log('login', username, password);

  let req = {
    API_NAME: 'mozzazcustomertoollogin',
    API_AUTHENTICATION_TYPE: 'auth_none',
    API_AUTHENTICATION_TOKEN: '',
    API_PAYLOAD: {
      Username: username,
      Password: password,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(BACKEND_ENDPOINT(), req);
  console.log(BACKEND_ENDPOINT(), 'req', req, 'response', data);
  handleExpiredToken(data);

  if (!data || data.err || !data.IsSuccess) {
    console.log('login err:', data);
    return data;
  }

  dispatch(actions.loginActions.loginSuccess(data.Data as LoginData));

  const session: LoginState = getState().loginReducer;
  console.log('session.sessionData', session.sessionData);
  return data;
}

export async function logout() {
  console.log('logout');

  localStorage.setItem('SecretKey', '');
  localStorage.setItem('Env', '');

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'mozzazcustomertoollogout',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: session.sessionData.CustomerId,
      SessionToken: session.sessionData.AuthToken,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  console.log('req', req, 'response', data);
  handleExpiredToken(data);

  if (!data || data.err) {
    console.log(data.err);
    return;
  }

  return data;
}

export async function forgotPassword(user: any, email: any) {
  console.log('forgotPassword');

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }
  console.log('session.sessionData.', session.sessionData);

  let req = {
    API_NAME: 'mozzazpstoolforgotpassword',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      Username: session.sessionData.Username,
      Email: session.sessionData.Email,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  console.log('req', req, 'response', data);
  handleExpiredToken(data);

  if (!data || data.err) {
    console.log(data.err);
    return;
  }

  return data;
}

export async function changePassword(oldPassword: any, newPassword: any) {
  console.log('changePassword');

  const session: LoginState = getState().loginReducer;
  if (!session.isLoggedIn || !session.sessionData) {
    return;
  }

  let req = {
    API_NAME: 'mozzazpstoolchangepassword',
    API_AUTHENTICATION_TYPE: 'auth_session',
    API_AUTHENTICATION_TOKEN: session.sessionData.AuthToken,
    AUTHENTICATION_OWNER_ID: session.sessionData.CustomerId,
    API_PAYLOAD: {
      CustomerId: session.sessionData.CustomerId,
      OldPassword: oldPassword,
      NewPassword: newPassword,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  console.log('req', req, 'response', data);
  handleExpiredToken(data);

  if (!data || data.err) {
    console.log(data.err);
    return;
  }

  return data;
}

export async function saveNewPassword(newPassword: any, auth: any) {
  console.log('resetPassword');

  let req = {
    API_NAME: 'mozzazportalsavenewpassword',
    API_AUTHENTICATION_TYPE: 'auth_none',
    API_AUTHENTICATION_TOKEN: auth,
    API_PAYLOAD: {
      Password: newPassword,
    },
  };
  req = { ...req, ...requestTemplate() };

  const { data } = await axios.post(`${BACKEND_ENDPOINT()}`, req);
  console.log('req', req, 'response', data);
  handleExpiredToken(data);

  if (!data || data.err) {
    console.log(data.err);
    return;
  }

  return data;
}

export async function handleExpiredToken(data: any) {
  // console.log('handle exp tok, data:', data);
  // console.log('Logout stale, deleting session');
  // dispatch(actions.loginActions.logout(null));
}
