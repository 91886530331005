import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ItemData {
  SystemEmailId: number;
  IsSent: boolean;
  UseDefaultTemplate: boolean;
  CustomTemplate: string;
  CustomOrgLogo: string;
  EmailType: string;
}

const RenderSystemEmailItem = (props: any) => {
  // if (props && props.index == 0) console.log('RenderSystemEmailItem', props);

  const [sendEmail, setSendEmail] = React.useState(props.systemEmail[props.index].DefaultBehaviour || false);
  const [defaultBehaviour, setDefaultBehaviour] = React.useState(true);
  const [defaultOrgLogo, setDefaultOrgLogo] = React.useState(props.systemEmail[props.index].DefaultOrgLogo);
  const [defaultTemplate, setDefaultTemplate] = React.useState(props.systemEmail[props.index].DefaultTemplate);

  useEffect(() => {
    // if (props.index == 0) console.log('item', props.systemEmail[props.index]);

    if (props.currentView == 'add' && props.index == 0) {
      for (let i = 0; i < props.systemEmailData.length; i++) {
        props.systemEmailData[i] = defaultData(i);
      }
      props.setSystemEmailData(props.systemEmailData);
    }

    populateForViewAndEdit();
  }, []);

  function defaultData(i: number) {
    let data: ItemData;
    data = {
      EmailType: props.systemEmail[i].EmailType,
      SystemEmailId: props.systemEmail[i].SystemEmailId,
      IsSent: props.systemEmail[i].DefaultBehaviour,
      UseDefaultTemplate: true,
      CustomTemplate: props.systemEmail[i].DefaultTemplate,
      CustomOrgLogo: props.systemEmail[i].DefaultOrgLogo,
    };
    return data;
  }

  function populateForViewAndEdit() {
    if (props.currentView == 'add') return;

    let data = props.systemEmailData.find((o: any) => o.SystemEmailId === props.systemEmail[props.index].SystemEmailId);
    //data not found
    if (data == undefined) {
      //set default values
      data = defaultData(props.index);
      //to make up for data that dont exist on view or edit
      props.systemEmailData[props.index] = data;
      props.setSystemEmailData(props.systemEmailData);
    }

    setSendEmail(data.IsSent);
    setDefaultBehaviour(data.UseDefaultTemplate);

    let dd = defaultData(props.index);

    setDefaultTemplate(data.CustomTemplate && data.CustomTemplate.length > 0 ? data.CustomTemplate : dd.CustomTemplate);
    setDefaultOrgLogo(data.CustomOrgLogo && data.CustomOrgLogo.length > 0 ? data.CustomOrgLogo : dd.CustomOrgLogo);
  }

  function readOnly() {
    return props.currentView == 'view' ? true : false;
  }

  return (
    <IntlProvider messages={{}} locale="de" defaultLocale="en">
      <Box
        component="div"
        sx={{
          display: 'flex',
          p: 1,
          flexDirection: 'column',
        }}
      >
        <Box>
          <Accordion style={{ padding: 0, border: '1px rgba(0, 0, 0, 0.23) solid', borderRadius: 5 }}>
            <AccordionSummary
              style={{ margin: 0, minHeight: 5 }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p style={{ margin: 0, minHeight: 5 }}>{props.systemEmail[props.index].EmailType}:</p>
            </AccordionSummary>

            <AccordionDetails>
              <Grid item xs={12} sm={6} md={3} style={{}}>
                <FormControlLabel
                  checked={sendEmail}
                  control={<Checkbox disabled={readOnly()} />}
                  label="Send Email"
                  labelPlacement="start"
                  onChange={(event, value) => {
                    setSendEmail(value);
                    props.systemEmailData[props.index].IsSent = Boolean(value);
                    props.setSystemEmailData(props.systemEmailData);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} style={{ display: 'flex' }}>
                <p style={{ padding: '0 20px', margin: 7 }}>Use Default:</p>

                <RadioGroup
                  value={defaultBehaviour}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="yes"
                  name="radio-buttons-group"
                  onChange={(event, value) => {
                    setDefaultBehaviour(value === 'true' ? true : false);
                    props.systemEmailData[props.index].UseDefaultTemplate = value === 'true' ? true : false;
                    props.setSystemEmailData(props.systemEmailData);
                  }}
                >
                  <FormControlLabel disabled={readOnly()} value={true} control={<Radio />} label="Yes" />
                  <FormControlLabel disabled={readOnly()} value={false} control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>

              {!defaultBehaviour && (
                <Box style={{ padding: 6, border: '1px rgba(0, 0, 0, 0.23) solid', borderRadius: 5 }}>
                  <div style={{ display: 'flex' }}>
                    <p style={{ margin: 0, padding: '0 20px' }}>Template Text:</p>
                    <textarea
                      disabled={readOnly()}
                      rows={3}
                      value={defaultTemplate}
                      aria-label="minimum height"
                      placeholder="Minimum 3 rows"
                      style={{ width: '78.9%' }}
                      onChange={(event) => {
                        setDefaultTemplate(event.target.value);
                        props.systemEmailData[props.index].CustomTemplate = event.target.value;
                        props.setSystemEmailData(props.systemEmailData);
                      }}
                    />
                  </div>

                  <div style={{ display: 'flex', paddingTop: 5 }}>
                    <p style={{ margin: 0, padding: '0 20px' }}>Logo URL:</p>
                    <TextField
                      value={defaultOrgLogo}
                      size="small"
                      inputProps={{ readOnly: readOnly() }}
                      style={{ width: '83%' }}
                      label=""
                      variant="outlined"
                      onChange={(event) => {
                        setDefaultOrgLogo(event.target.value);
                        props.systemEmailData[props.index].CustomOrgLogo = event.target.value;
                        props.setSystemEmailData(props.systemEmailData);
                      }}
                    />
                  </div>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </IntlProvider>
  );
};

export default React.memo(RenderSystemEmailItem);
