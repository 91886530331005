import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";

export const FormInputText = ({ name, control,question, isEdit }: FormInputProps) => {
  const inputType = question?.QuestionType === "numeric" ? "number" : "text";
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={isEdit ? question?.textResponse : undefined}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          type={inputType}
          helperText={error ? error.message : null}
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          variant="outlined"
          multiline={question?.QuestionType === "textarea" ? true : false}
          rows={question?.QuestionType === "textarea" ? "1" : "4"}
          sx={{width:"75%"}}
          inputProps={{style:{
            height: "40px",
            padding: "0px 5px 0px 5px",
            width:"100%"
          }}}
        />
      )}
    />
  );
};