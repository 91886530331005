import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';
import MuiToggleButton from '@mui/material/ToggleButton';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

export const FormInputToggle: React.FC<FormInputProps> = ({
  name,
  control,
  question,
  isEdit
}) => {

  const [alignment, setAlignment] = React.useState<boolean | undefined>(isEdit ? (question?.textResponse === "" ? undefined : (question?.textResponse === "Yes" ? true : false)) : undefined);
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: boolean,
  ) => {
    setAlignment(newAlignment);
  };

  const ToggleButton = styled(MuiToggleButton)(() => ({
    width: "75px",
    height: "40px",
    borderColor: "#0074BF",
    fontSize: "20px",
    color: "#0074BF",
    fontFamily: "ProximaNovaSemibold",
    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'white',
      backgroundColor: "#0074BF",
    },
  }));

  return (
    <FormControl component="fieldset">
      <Controller
        name={name}
        control={control}
        defaultValue={isEdit ? (question?.textResponse === "" ? undefined : (question?.textResponse === "Yes" ? "Yes" : "No")) : undefined }
        render={(props : any) => (
          <ToggleButtonGroup
            color="primary"
            exclusive
            size='small'
            onChange={(event , value) => {
              props.field.onChange(value);
              handleChange(event, value)
            }}
            aria-label="Platform"
            value={alignment}
          >
            <ToggleButton value="Yes" key="Yes">Yes</ToggleButton>
            <ToggleButton value="No" key="No">No</ToggleButton>
          </ToggleButtonGroup>
        )}
      />
    </FormControl>
  );
};
