import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';

const styles = {
  tab: {
    fontSize: '20px', 
    fontFamily: 'ProximaNovaSoftSemibold', 
    textTransform: 'none', 
    marginRight: '30px',
    color: '#767676'
  },
  tabs: {
    width: '80%', 
    height: '48px'
  },
  tabContainer: {
    mt:2, 
    mx:3, 
    borderBottom: 2, 
    borderColor: 'custom_nav_background.main'
  }
}

export const PatientDetails = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    switch (window.location.pathname) {
      case "/patients/details/profile":
        setValue(0);
        break;
      case "/patients/details/devices":
        setValue(1);
        break;
      case "/patients/details/forms":
        setValue(2);
        break;
      default:
        setValue(3);
        break;
    }
  }, []);

  return (
    <Box sx = {{ width: '100%' }}>
      <Box id = "tabContainer" sx = { styles.tabContainer }>
        <Tabs
          value = {value}
          onChange = {handleChange}
          textColor = 'primary'
          indicatorColor = 'primary'
          sx = { styles.tabs }
        >
          <Tab component = {Link} label = "Profile" id = "profile-tab" to = "/patients/details/profile" sx = { styles.tab } />
          <Tab component = {Link} label = "Devices" id = "devices-tab" to = "/patients/details/devices" sx = { styles.tab }/>
          <Tab component = {Link} label = "Forms" id = "forms-tab" to = "/patients/details/forms" sx = { styles.tab }/>
        </Tabs>
      </Box>
      <Outlet></Outlet>
    </Box>
  );
}