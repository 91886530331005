import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import rootReducer from './slices';
import { configureStore } from '@reduxjs/toolkit';
import { actions } from './slices';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['loginReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

let persistor = persistStore(store);

const getState = store.getState;
const dispatch = store.dispatch;

export { store, persistor, actions, getState, dispatch };
