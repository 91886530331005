import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { IntlProvider } from 'react-intl';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { PopUp } from '../../../components/PopUp';
import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import { FormQuestion, reportMap } from '../../../data/model/formQuestion';
import { useNavigate } from 'react-router-dom';
import { FormInputDropdown } from '../../../components/form/FormInputDropdown';
import { FormInputRadio } from '../../../components/form/FormInputRadio';
import { FormInputCheckbox } from '../../../components/form/FormInputCheckbox';
import { FormInputSlider } from '../../../components/form/FormInputSlider';
import { FormInputToggle } from '../../../components/form/FormInputToggle';
import { FormInputText } from '../../../components/form/FormInputText';
import { FormInputDate } from '../../../components/form/FormInputDate';
import { FormInputTime } from '../../../components/form/FormInputTime';
import { FormInputSignature } from '../../../components/form/FormInputSignature';
import { useForm } from 'react-hook-form';
import { AlertMessages, IPopUpMessage } from '../../../data/popup-messages';

export const AddFormEntry = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState<IPopUpMessage>();

  const navigate = useNavigate();
  const { handleSubmit, control, setValue, formState } = useForm({
    mode: 'onChange',
  });
  const { dirtyFields } = formState;

  const handleOnSubmit = (answers: any) => {
    const unansweredQuestion = validaForm(answers);
    if (unansweredQuestion.length > 0) {
      console.log(unansweredQuestion);
      return;
    }
    console.log(answers);
  };

  const validaForm = (answers: any) => {
    const requiredQuestions: string[] = [];
    formData.Data.Questions.map((question) => {
      if (question.Required) {
        if (
          answers[question.QuestionId] === undefined ||
          answers[question.QuestionId] === ''
        ) {
          requiredQuestions.push(question.QuestionText);
        }
      }
      return '';
    });
    return requiredQuestions;
  };

  const goToBack = () => {
    if (Object.keys(dirtyFields).length !== 0) {
      const messageObj: IPopUpMessage = AlertMessages.form.exit;
      messageObj.secondaryBtnEvent = () =>{ goToBack()};
      setPopUpMessage(messageObj);
      setShowPopup(true);
      return false;
    } else {
      navigate(-1);
    }
  };

  const getAnswerControl = (question: FormQuestion) => {
    switch (question.QuestionType) {
      case 'dropdown':
        return (
          <FormInputDropdown
            name={question.QuestionId + ''}
            control={control}
            question={question}
          ></FormInputDropdown>
        );
      case 'radio':
        return (
          <FormInputRadio
            name={question.QuestionId + ''}
            control={control}
            question={question}
          ></FormInputRadio>
        );
      case 'checkbox':
        return (
          <FormInputCheckbox
            name={question.QuestionId + ''}
            control={control}
            setValue={setValue}
            question={question}
          ></FormInputCheckbox>
        );
      case 'ratingbar':
        return (
          <FormInputSlider
            name={question.QuestionId + ''}
            control={control}
            setValue={setValue}
            question={question}
          ></FormInputSlider>
        );
      case 'yesno':
        return (
          <FormInputToggle
            name={question.QuestionId + ''}
            control={control}
            question={question}
          ></FormInputToggle>
        );
      case 'datepicker':
        return (
          <FormInputDate
            name={question.QuestionId + ''}
            control={control}
            question={question}
          ></FormInputDate>
        );
      case 'timepicker':
        return (
          <FormInputTime
            name={question.QuestionId + ''}
            control={control}
            question={question}
          ></FormInputTime>
        );
      // case 'eSignature':
      //   return(
      //     <FormInputSignature
      //       name={question.QuestionId + ''}
      //       control={control}
      //       question={question}
      //     ></FormInputSignature>
      //   );
      case 'shortanswer':
      case 'numeric':
      case 'textarea':
        return (
          <FormInputText
            name={question.QuestionId + ''}
            control={control}
            question={question}
          ></FormInputText>
        );
    }
  };

  const Question = (props: any) => {
    const question = reportMap(props.question);
    return (
      <Box
        component="div"
        sx={{
          p: 0,
          m: 0,
          display: 'flex',
          fontFamily: 'ProximaNovaMedium',
          fontSize: 20,
          color: 'custom_grey.main',
          width: '100%',
        }}
      >
        <span
          style={{
            flex: 1,
            padding: 5,
            paddingRight: 20,
            alignContent: 'center',
          }}
        >
          {question.Required ? (
            <>
              <span
                style={{
                  flex: 1,
                  paddingRight: 2,
                  alignContent: 'center',
                  color: 'red',
                }}
              >
                *
              </span>
              {question.QuestionText}
            </>
          ) : (
            question.QuestionText
          )}
        </span>
        <div style={{ flex: 1, padding: 5, paddingLeft: 20 }}>
          {' '}
          {getAnswerControl(question)}
        </div>
      </Box>
    );
  };
  return (
    <IntlProvider messages={{}} locale="de" defaultLocale="en">
      <Box
        component="div"
        sx={{
          display: 'flex',
          p: 1,
          flexDirection: 'column',
          paddingLeft: 5,
          paddingRight: 5,
          height: '100%',
        }}
      >
        <Box component="div" sx={{ display: 'flex', width: '100%' }}>
          <Box
            fontSize={20}
            component="div"
            sx={{
              display: 'flex',
              width: '100%',
              color: 'custom_dark_grey.main',
            }}
          >
            <IconButton type="button" aria-label="Back" onClick={goToBack}>
              <ArrowBackIosIcon color="primary" />
            </IconButton>
            <p style={{ fontFamily: 'ProximaNovaSemibold', fontSize: '20px' }}>
              Form Nameeeeeeeeeee
            </p>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              height: 40,
              alignSelf: 'center',
              marginRight: 3,
              fontFamily: 'ProximaNovaSoftSemibold',
              fontSize: 20,
              minWidth: 100,
            }}
            onClick={goToBack}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              height: 40,
              alignSelf: 'center',
              fontFamily: 'ProximaNovaSoftSemibold',
              fontSize: 20,
              minWidth: 100,
            }}
            type="submit"
            onClick={handleSubmit(handleOnSubmit)}
          >
            Save
          </Button>
        </Box>
        <Paper
          elevation={0}
          sx={{ p: 0, width: '100%', overflow: 'hidden', height: '100%' }}
          component="form"
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: '100%',
              p: 0,
              '& ul': { padding: 0 },
            }}
          >
            {
              <>
                <ListSubheader>
                  <Box
                    component="div"
                    sx={{
                      p: 0,
                      m: 0,
                      display: 'flex',
                      fontFamily: 'ProximaNovaSemibold',
                      fontSize: 20,
                      color: 'custom_grey.custom_grey',
                    }}
                  >
                    <span
                      style={{
                        flex: 1,
                        padding: 5,
                        paddingRight: 20,
                        alignContent: 'center',
                      }}
                    >
                      Question
                    </span>
                    <span
                      style={{
                        flex: 1,
                        padding: 5,
                        paddingLeft: 20,
                        alignContent: 'center',
                      }}
                    >
                      Answer
                    </span>
                  </Box>
                </ListSubheader>
                {formData.Data.Questions.map((question) => {
                  if (question.QuestionType === 'timer') return;
                  return (
                    <ListItem
                      key={`item-${question.QuestionId}`}
                      divider={true}
                    >
                      <Question question={question}></Question>
                    </ListItem>
                  );
                })}
              </>
            }
          </List>
        </Paper>
        {showPopup && (
          <PopUp
            open={true}
            header={popUpMessage?.title || ''}
            message={popUpMessage?.message || ''}
            primaryBtnText={popUpMessage?.primaryBtnText || ''}
            secondaryBtnText={popUpMessage?.secondaryBtnText}
            primaryBtnEvent={() => {
              setShowPopup(false);
              if(popUpMessage?.primaryBtnEvent)  
                popUpMessage?.primaryBtnEvent();
            }}
            secondaryBtnEvent={() => {
              setShowPopup(false);
              if(popUpMessage?.secondaryBtnEvent)  
                popUpMessage?.secondaryBtnEvent();
            }}
          ></PopUp>
        )}
      </Box>
    </IntlProvider>
  );
};

const formData = {
  IsSuccess: true,
  Data: {
    FormId: 519189,
    FormText: '',
    FormName: 'New Form',
    IsEditable: true,
    Questions: [
      {
        QuestionId: 5480425,
        QuestionText: 'date (bold) - left aligned',
        RichText: '<p><b>date (bold) - left aligned</b></p>',
        QuestionType: 'datepicker',
        Required: true,
        Answers: [
          { AnswerId: 31674410, AnswerText: '', OriginAnswerId: 31674380 },
        ],
        OriginQuestionId: 5479748,
      },
      {
        QuestionId: 5480426,
        QuestionText: 'dropdown (italic) - right aligned',
        RichText:
          '<p style="text-align: right"><i>dropdown (italic) - right aligned</i></p>',
        QuestionType: 'dropdown',
        Required: false,
        Answers: [
          { AnswerId: 31674411, AnswerText: '1', OriginAnswerId: 31674381 },
          { AnswerId: 31674412, AnswerText: '2', OriginAnswerId: 31674382 },
          { AnswerId: 31674413, AnswerText: '3', OriginAnswerId: 31674383 },
        ],
        OriginQuestionId: 5479749,
      },
      {
        QuestionId: 5480427,
        QuestionText:
          'multiple choices - multiple answer (underlined) - center aligned',
        RichText:
          '<p style="text-align: center"><u>multiple choices - multiple answer (underlined) - center aligned</u></p>',
        QuestionType: 'checkbox',
        Required: false,
        Answers: [
          { AnswerId: 31674414, AnswerText: 'a', OriginAnswerId: 31674384 },
          { AnswerId: 31674415, AnswerText: 's', OriginAnswerId: 31674385 },
          { AnswerId: 31674416, AnswerText: 'd', OriginAnswerId: 31674386 },
          { AnswerId: 31674417, AnswerText: 'f', OriginAnswerId: 31674387 },
        ],
        OriginQuestionId: 5479751,
      },
      {
        QuestionId: 5480428,
        QuestionText: 'multiple -&gt; single (strikethrough) - justified',
        RichText:
          '<p style="text-align: justify"><strike>multiple -&gt; single (strikethrough) - justified</strike></p>',
        QuestionType: 'radio',
        Required: false,
        Answers: [
          { AnswerId: 31674418, AnswerText: 'q', OriginAnswerId: 31674388 },
          { AnswerId: 31674419, AnswerText: 'w', OriginAnswerId: 31674389 },
          { AnswerId: 31674420, AnswerText: 'e', OriginAnswerId: 31674390 },
        ],
        OriginQuestionId: 5479752,
      },
      {
        QuestionId: 5480429,
        QuestionText: 'Link to Google.ca(1 indent)',
        RichText:
          '<h3 style="margin-left: 25px"><a href="http://Google.ca" target="_blank">Link to Google.ca</a>&nbsp;(1 indent)</h3>',
        QuestionType: 'readonly',
        Required: false,
        Answers: [
          { AnswerId: 31674421, AnswerText: '', OriginAnswerId: 31674391 },
        ],
        OriginQuestionId: 5479756,
      },
      {
        QuestionId: 5480430,
        QuestionText:
          'short answer (ordered list)\n  1. 123\n  1. 23243\n  1. 664',
        RichText:
          '<p>short answer (ordered list)</p><ol><li>123</li><li>23243</li><li>664</li></ol>',
        QuestionType: 'shortanswer',
        Required: false,
        Answers: [
          { AnswerId: 31674422, AnswerText: '', OriginAnswerId: 31674266 },
        ],
        OriginQuestionId: 5479757,
      },
      {
        QuestionId: 5480431,
        QuestionText:
          'textarea (unordered list)\n  -  asdf\n  -  gfds\n  -  qwe',
        RichText:
          '<div>textarea (unordered list)</div><ul><li>asdf</li><li>gfds</li><li>qwe</li></ul>',
        QuestionType: 'textarea',
        Required: false,
        Answers: [
          { AnswerId: 31674423, AnswerText: '', OriginAnswerId: 31674267 },
        ],
        OriginQuestionId: 5479758,
      },
      {
        QuestionId: 5480432,
        QuestionText: 'num default',
        RichText: '<p>num default</p>',
        QuestionType: 'numeric',
        Required: false,
        Answers: [
          { AnswerId: 31674424, AnswerText: '', OriginAnswerId: 31673045 },
        ],
        OriginQuestionId: 5479753,
      },
      {
        QuestionId: 5480433,
        QuestionText: 'esign (H1)',
        RichText: '<h1>esign (H1)</h1>',
        QuestionType: 'eSignature',
        Required: false,
        Answers: [
          { AnswerId: 31674425, AnswerText: '', OriginAnswerId: 31674425 },
        ],
        OriginQuestionId: 5479750,
      },
      {
        QuestionId: 5480434,
        QuestionText: 'counter (H2)',
        RichText: '<h2>counter (H2)</h2>',
        QuestionType: 'numeric',
        Required: false,
        Answers: [
          { AnswerId: 31674426, AnswerText: '1', OriginAnswerId: 31674426 },
        ],
        OriginQuestionId: 5479754,
      },
      {
        QuestionId: 5480435,
        QuestionText: 'slide (h3)',
        RichText: '<h3>slide (h3)</h3>',
        QuestionType: 'ratingbar',
        Required: false,
        Answers: [
          { AnswerId: 31674427, AnswerText: '100', OriginAnswerId: 31674427 },
        ],
        OriginQuestionId: 5479755,
      },
      {
        QuestionId: 5480436,
        QuestionText: 'time 12 (h4)',
        RichText:
          '<h4>time 12&nbsp;<shortprompt>fdfhknlk.ml gkjhjklj;l,kl;;l jklkjmp,kp fdfhknlk.ml gkjhjklj;l,kl;;l jklkjmp,kpfdfhknlk.ml gkjhjklj;l,kl;;l jklkjmp,kp fdfhknlk.ml gkjhjklj;l,kl;;l jklkjmp,kp fdfhknlk.ml gkjhjklj;l,kl;;l jklkjmp,kp fdfhknlk.ml gkjhjklj;l,kl;;l jklkjmp,kp fdfhknlk.ml gkjhjklj;l,kl;;l jklkjmp,kp fdfhknlk.fdfhknlk.ml gkjhjklj;l,kl;;l jklkjmp,kp fdfhknlk.ml gkjhjklj;l,kl;;l jklkjmp,kp fdfhknlk.ml gkjhjklj;l,kl;;l jklkjmp,kp fdfhknlk.ml gkjhjklj;l,kl;;l jklkjmp,kp fdfhknlk.ml gkjhjklj;l,kl;;l jklkjm shes</shortprompt><span>&nbsp;(h4)</span></h4>',
        QuestionType: 'timepicker',
        Required: true,
        Answers: [
          { AnswerId: 31674428, AnswerText: '12', OriginAnswerId: 31674428 },
        ],
        OriginQuestionId: 5479759,
      },
      {
        QuestionId: 5480437,
        QuestionText: 'time 24 (h5)',
        RichText: '<h5>time 24 (h5)</h5>',
        QuestionType: 'timepicker',
        Required: false,
        Answers: [
          { AnswerId: 31674429, AnswerText: '24', OriginAnswerId: 31674429 },
        ],
        OriginQuestionId: 5479760,
      },
      {
        QuestionId: 5480438,
        QuestionText: 'Timer (h6)',
        RichText: '<h6>Timer (h6)</h6>',
        QuestionType: 'timer',
        Required: false,
        Answers: [
          { AnswerId: 31674430, AnswerText: '', OriginAnswerId: 31674430 },
        ],
        OriginQuestionId: 5479761,
      },
      {
        QuestionId: 5480439,
        QuestionText: 'YES / NO',
        RichText: '<h6>YES / NO</h6>',
        QuestionType: 'yesno',
        Required: true,
        Answers: [
          { AnswerId: 31674431, AnswerText: '', OriginAnswerId: 31674431 },
        ],
        OriginQuestionId: 5479762,
      },
    ],
    DefaultView: 1,
  },
};
