import { AppLogo } from '../../../components/AppLogo';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import DashboardIcon from '@mui/icons-material/Dashboard';
import IconButton from '@mui/material/IconButton';
import InsightsIcon from '@mui/icons-material/Insights';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Typography from '@mui/material/Typography';
import { DrawerWidth, DrawerClosedWidth } from '../../../components/Constant';


const openedMixin = (theme: Theme): CSSObject => ({
  width: DrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-end',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DrawerWidth,
    width: `calc(100% - ${DrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: DrawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export function SideBar(props: any) {
  const [open, setOpen] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    switch (window.location.pathname) {
      case '/dashboard':
        setSelectedIndex(0);
        break;
      case '/organization':
        setSelectedIndex(1);
        break;
      case '/accountTransfer':
        setSelectedIndex(2);
        break;
      default:
        break;
    }
  }, [window.location.pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
    props.setWidth(DrawerWidth);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.setWidth(DrawerClosedWidth);
  };

  const returnIcon = (index: number) => {
    return <PersonOutlineIcon />;
    if (index == 0) return <DashboardIcon />;
    if (index == 1) return <LocationCityIcon />;
  };

  return (
    <Box>
      <Drawer PaperProps={{ sx: { backgroundColor: 'custom_nav_background.main' } }} variant="permanent" open={open}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <AppLogo />
          <List>
            {
              [
                // 'Dashboard', 'Organization List',
                'Account Transfer'].map((text, index) => (
                  <ListItem
                    key={text}
                    disablePadding
                    sx={{ display: 'block' }}
                    title={text}
                    onClick={() => {
                      setSelectedIndex(index);
                      if (text === 'Dashboard') {
                        navigate('/dashboard');
                      }
                      if (text === 'Organization List') {
                        navigate('/organization', { state: { id: 1, name: 'list' } });
                      }
                      if (text === 'Account Transfer') {
                        navigate('/accountTransfer');
                      }
                    }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                          color: selectedIndex === index ? 'primary.main' : 'custom_grey.main',
                        }}
                      >
                        {returnIcon(index)}
                      </ListItemIcon>
                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                          fontSize: '20px',
                          color: selectedIndex === index ? 'primary.main' : 'custom_grey.main',
                          fontFamily: 'proximaNovaSoftSemibold',
                        }}
                      >
                        {text}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ))}
          </List>
        </Box>

      </Drawer>
    </Box>
  );
}
