export function requestTemplate() {


  let secretKey = process.env.REACT_APP_SECRET_KEY;// localStorage.getItem('SecretKey');
  return {
    API_SECRET_KEY: secretKey,
    API_VERSION: '1.0',
    CLIENT_VERSION: '',
  };
}
