import { combineReducers } from "redux";
import * as loginSlice from "./login";
import * as userSlice from "./user";

export default combineReducers({
  loginReducer: loginSlice.reducer,
  userReducer: userSlice.reducer
});

export const actions = {
  loginActions: loginSlice.actions,
  userActions: userSlice.actions,
};