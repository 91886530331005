import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useState } from 'react';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface PopUpProps {
  open: boolean;
  header: string;
  message: string;
  primaryBtnText: string;
  secondaryBtnText?: string;
  primaryBtnEvent?: Function;
  secondaryBtnEvent?: Function;
}

export const PopUp = (props: PopUpProps) => {
  const [open, setOpen] = useState(props.open);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          minWidth: "25% !important",
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" sx={{
            height: 40,
            fontFamily: 'ProximaNovaSoftSemibold',
            fontSize: 22,
            color:"secondary.main",
            pb: '10px'            
          }}>{props.header}</DialogTitle>
      <DialogContent sx={{pt: '20px !important'}}>
        <DialogContentText id="alert-dialog-description" sx={{fontSize: 18, color: "custom_grey.main", fontFamily: "ProximaNovaMedium" }}>
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{px:'24px', pb:'16px'}}>
        {props.secondaryBtnText && (
          <Button
          color='primary'
          variant='outlined'
          sx={{
            p:0,
            height: 40,
            alignSelf: 'center',
            fontFamily: 'ProximaNovaSoftSemibold',
            fontSize: 20,
            minWidth: 100,
          }}
            onClick={() => {
              handleClose();
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              props.secondaryBtnEvent !== undefined
                ? props.secondaryBtnEvent()
                : undefined;
            }}
          >
            {props.secondaryBtnText || 'OK'}
          </Button>
        )}
        <Button
          color='primary'
          variant='contained'
          sx={{
            p:0,
            height: 40,
            alignSelf: 'center',
            fontFamily: 'ProximaNovaSoftSemibold',
            fontSize: 20,
            minWidth: 100,
          }}
          onClick={() => {
            handleClose();
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            props.primaryBtnEvent !== undefined
              ? props.primaryBtnEvent()
              : undefined;
          }}
          autoFocus
        >
          {props.primaryBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
