import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
const DATE_FORMAT = 'dd-MMM-yy';

export const FormInputTime = ({ name, control,question, isEdit }: FormInputProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        defaultValue={isEdit ? question?.textResponse : undefined}
        render={({ field, fieldState, formState }) => (
          <TimePicker
            ampm={(question && question.Answers[0].AnswerText === "12") ? true: false }
            value={isEdit ? question?.textResponse : null}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                sx={{
                  svg: '#0074BF',
                }}
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'Select Time',
                }}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};
