import FormLabel from '@mui/material/FormLabel';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';

export const FormInputSlider = ({
  name,
  control,
  setValue,
  question,
  isEdit
}: FormInputProps) => {
  const [sliderValue, setSliderValue] = React.useState<number>(isEdit?Number((question?.Answers[0].AnswerText || "0")) : 0);

  useEffect(() => {
    if (sliderValue) setValue(name, sliderValue);
  }, [sliderValue]);

  const handleChange = (event: any, newValue: number | number[]) => {
    setSliderValue(newValue as number);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState, formState }) => (
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <span>0</span>
            <Slider
              value={sliderValue}
              onChange={handleChange}
              defaultValue={isEdit?Number((question?.Answers[0].AnswerText || "0")) : 0}
              valueLabelDisplay="auto"
              min={0}
              max={Number(question?.Answers[0].AnswerText || '100')}
              step={1}
              sx={{ width: '75%' }}
            />
            <span>{question?.Answers[0].AnswerText || '100'}</span>
          </Stack>
        )}
      />
    </>
  );
};
